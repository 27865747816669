import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';
// utils
import axios from '../utils/axios';
import { getAccessToken, isValidToken, setSession, setUserInfo, shouldRefresh } from '../utils/jwt';
import { ACCESS_TOKEN } from '../utils/constant';
import businessService from '../services/businessService';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user_info: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user_info } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user_info
    };
  },
  LOGIN: (state, action) => {
    const { user_info } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user_info
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user_info: null
  }),
  REGISTER: (state, action) => {
    const { user_info } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user_info
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        let token = await getAccessToken();
        if (token) {
          if (shouldRefresh(token)) {
            token = await authService.renewToken();
            localStorage.setItem(ACCESS_TOKEN, token);
          }
          setSession(token);
          const response = await authService.getUserInfo();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user_info: response?.data
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user_info: null
            }
          });
        }
        // const accessToken = window.localStorage.getItem('accessToken');
        // const user = window.localStorage.getItem('accessToken');
        // setSession(token);
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user_info: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (data) => {
    try {
      const { access_token, refresh_token, user_info } = data;
      setSession(access_token, refresh_token);
      // const user = {
      //   id: user_info.id,
      //   first_name: user_info.first_name,
      //   last_name: user_info.last_name,
      //   phone_number: user_info.phone_number,
      //   avatar: user_info.avatar,
      //   user_roles: user_info.user_roles
      // };
      // setUserInfo(JSON.stringify(user));
      dispatch({
        type: 'LOGIN',
        payload: {
          user_info
        }
      });
      return { status: true, data, message: 'login success' };
    } catch (error) {
      console.log(error);
    }
  };

  // const login = async (data) => {
  //   try {
  //     const response = await authService.loginWithPhone(data);
  //     if (response.status === 200) {
  //       const { token, user_info } = response.data;
  //       setSession(token);
  //       const user = {
  //         id: user_info.id,
  //         fullName: user_info.full_name,
  //         phoneNumber: user_info.phone_number,
  //         avatar: user_info.avatar,
  //         email: user_info.email
  //       };
  //       setUserInfo(JSON.stringify(user));
  //       dispatch({
  //         type: 'LOGIN',
  //         payload: {
  //           user: user_info
  //         }
  //       });
  //       return { status: true, data: response.data, message: response.message };
  //     }
  //     return { status: false, message: response.message };
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user_info } = response.data;

    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user_info
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
