export default {
    START_FETCH_BANNER: 'START_FETCH_BANNER',
    FETCH_BANNER_FAILURE: 'FETCH_BANNER_FAILURE',
    FETCH_BANNER_SUCCESS: 'FETCH_BANNER_SUCCESS',
    START_CREATE_BANNER:'START_CREATE_BANNER',
    CREATE_BANNER_FAILURE: 'CREATE_BANNER_FAILURE',
    CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
    START_FETCH_BANNER_ONE: 'START_FETCH_BANNER_ONE',
    FETCH_BANNER_ONE_FAILURE:'FETCH_BANNER_ONE_FAILURE',
    FETCH_BANNER_ONE_SUCCESS:'FETCH_BANNER_ONE_SUCCESS',
    FETCH_ERROR: 'FETCH_ERROR',
    START_FETCH_BANNER_TYPE:'START_FETCH_BANNER_TYPE',
    FETCH_BANNER_TYPE_FAILURE:'FETCH_BANNER_TYPE_FAILURE',
    FETCH_BANNER_TYPE_SUCCESS:'FETCH_BANNER_TYPE_SUCCESS',
    START_UPDATE_BANNER:'START_UPDATE_BANNER',
    UPDATE_BANNER_FAILURE:'UPDATE_BANNER_FAILURE',
    UPDATE_BANNER_SUCCESS:'UPDATE_BANNER_SUCCESS',
    SEND_NOTI_SUCCESS:'SEND_NOTI_SUCCESS',
    START_SEND_NOTI:'START_SEND_NOTI',
    SEND_NOTI_FAILURE:'SEND_NOTI_FAILURE'
  }
  