export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const SECRET_KEY = process.env.REACT_APP_OTP_SECRET_KEY;

export const api = {
  LOGIN: `${BASE_URL}/login-firebase`,
  USER: `${BASE_URL}/ms-user-management/api`,
  BUSINESS: `${BASE_URL}/ms-business-management/api`,
  TRANSACTION: `${BASE_URL}/ms-transaction-management/api`,
  ECOM: `${BASE_URL}/ms-ecom-adapter/api`
};

export const API_PRE_UP = 'ms-media-management/api/media/pre_up';
export const API_POS_UP = 'ms-media-management/api/media/pos_up';
export const API_METADATA = 'ms-meta-data/api';
export const API_BANNER = `${API_METADATA}/banner`;
export const API_TERM = `${API_METADATA}/term`;
export const API_TAXONOMY = `${API_METADATA}/taxonomy`;
export const API_TERM_TAXONOMY = `${API_METADATA}/term-taxonomy`;
export const API_TERM_RELATIONSHIP = `${API_METADATA}/term-relationship`;
export const API_CHAT = 'ms-chat-2/api';
export const API_USER = 'ms-user-management/api';
export const API_BUSINESS = 'ms-business-management/api';

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const REFRESH_TOKEN_BEFORE = 24 * 60 * 60000;

export const BASE_URL_MVT = process.env.REACT_APP_BACKEND_BASE_URL_MVT;
