import React, { useMemo } from 'react';
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user_info } = useAuth();
  const { name, color } = useMemo(() => createAvatar(user_info?.last_name || user_info?.first_name), [user_info]);

  return (
    <MAvatar
      src={user_info?.avatar}
      alt={user_info?.last_name || user_info?.first_name}
      color={user_info?.avatar ? 'default' : color}
      {...other}
    >
      {name}
    </MAvatar>
  );
}
