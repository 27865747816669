import posType from '../action/action.type/pos.types';

const initialState = {
  highlightItems: [],
  subscriptions: [],
  services: [],
  devices: [],
  iphones: [],
  ipads: [],
  macbooks: [],
  subscriptionDetail: null,
  subscriptionByBiz: null,
  error: '',
  order: { data: [], totalPage: 1 }
};
const posReducer = (state = initialState, action) => {
  switch (action.type) {
    case posType.UPDATE_HIGHLIGHT_ITEMS:
      return { ...state, highlightItems: action.data };
    //subscriptions
    case posType.FETCH_LIST_SUBSCRIPTION_START:
      return { ...state };
    case posType.FETCH_LIST_SUBSCRIPTION_SUCCESS: {
      if (action?.params === 'service') return { ...state, services: action.data };
      if (action?.params === 'device') return { ...state, devices: action.data };
      if (action?.params === 'iphone') return { ...state, iphones: action.data };
      if (action?.params === 'ipad') return { ...state, ipads: action.data };
      if (action?.params === 'macbook') return { ...state, macbooks: action.data };
      return { ...state, subscriptions: action.data };
    }
    case posType.FETCH_LIST_SUBSCRIPTION_FAILURE:
      return { ...state, error: action.message };

    case posType.FETCH_SUBSCRIPTION_DETAIL_START:
      return { ...state, subscriptionDetail: null };
    case posType.FETCH_SUBSCRIPTION_DETAIL_SUCCESS:
      return { ...state, subscriptionDetail: action.data };
    case posType.FETCH_SUBSCRIPTION_DETAIL_FAILURE:
      return { ...state, subscriptionDetail: null };
    case posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_START:
      return { ...state, subscriptionByBiz: null };
    case posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_SUCCESS:
      return { ...state, subscriptionByBiz: action.data };
    case posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_FAILURE:
      return { ...state, subscriptionByBiz: null, error: action.message };

    //order
    case posType.FETCH_LIST_POS_ORDER_START:
      return { ...state, order: { data: [], totalPage: 1 } };
    case posType.FETCH_LIST_POS_ORDER_SUCCESS:
      return { ...state, order: action.data };
    case posType.FETCH_LIST_POS_ORDER_FAILURE:
      return { ...state, order: { data: [], totalPage: 1 }, error: action.message };

    case posType.RESET_SUBSCRIPTION_DETAIL:
      return { ...state, subscriptionDetail: null };
    default:
      return state;
  }
};

export default posReducer;
