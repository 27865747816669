import pack from '../action/action.type/campaign.types';

const initialState = {
  isLoading: false,
  successCreateCampaign: null,
  successUpdateCampaign: null,
  createCampaignDataResponse: null,
  campaignDetail: null,
  successGetCampaignDetail: null,
  campaigns: [],
  totalItems: 0,
  totalPage: 1,
  successRequestApproveCampaign: null,
  errRequestApprove: '',
  shops: [],
  errCreateCampaign: null,
  errUpdateCampaign: null,
  successCopyCampaign: null,
  errCopyCampaign: null,
  successDeleteCampaign: null,
  errDeleteCampaign: null,
  successSyncCampaign: null,
  isLoadingSync: false,
  isLoadingForControl: false,
  successForControl: null,
  exportUrl: null,
  successApproveCampaign: null,
  errApproveCampaign: '',
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case pack.CREATE_CAMPAIGN_START:
    case pack.GET_CAMPAIGN_DETAIL_START:
    case pack.GET_LIST_CAMPAIGN_START:
    case pack.COPY_CAMPAIGN_START:
    case pack.DELETE_CAMPAIGN_START:
      return { ...state,
        isLoading: true,
        successCreateCampaign: null,
        campaignDetail: null,
        campaigns: [],
        createCampaignDataResponse: null,
        successRequestApproveCampaign: null,
        errRequestApprove: '',
        totalPage: 1,
        totalItems: 0,
        errCreateCampaign: null,
        successUpdateCampaign: null,
        errUpdateCampaign: null,
        successCopyCampaign: null,
        errCopyCampaign: null,
        successDeleteCampaign: null,
        errDeleteCampaign: null,
      };
    case pack.REQUEST_APPROVE_CAMPAIGN_START:
      return {
        ...state,
        isLoading: true,
        successRequestApproveCampaign: null,
        errRequestApprove: ''
      }
    case pack.GET_LIST_SHOP_START:
      return {
        ...state,
        shops: []
      }
    case pack.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successCreateCampaign: true,
        createCampaignDataResponse: action.data
      };
    case pack.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successUpdateCampaign: true,
        errUpdateCampaign: ''
      };
    case pack.GET_CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaignDetail: action.data,
        successGetCampaignDetail: true,
      };
    case pack.GET_LIST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: action.data,
        totalItems: action.totalItems,
        totalPage: action.totalPage,
      };
    case pack.REQUEST_APPROVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successRequestApproveCampaign: true
      };

    case pack.GET_LIST_SHOP_SUCCESS:
      return {
        ...state,
        shops: action.data,
      };
    case pack.COPY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        successCopyCampaign: true,
        errCopyCampaign: ''
      };
    case pack.APPROVE_CAMPAIGN_START:
      return {
        ...state,
        successApproveCampaign: true,
        errApproveCampaign: ''
      };
    case pack.APPROVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        successApproveCampaign: true,
        errApproveCampaign: ''
      };
    case pack.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        successDeleteCampaign: true,
        errDeleteCampaign: ''
      };

    case pack.CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isLoading: false,
        successCreateCampaign: false,
        errCreateCampaign: action.message
      }
    case pack.UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isLoading: false,
        successUpdateCampaign: false,
        errUpdateCampaign: action.message
      }
    case pack.GET_CAMPAIGN_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        successGetCampaignDetail: false
      }
    case pack.REQUEST_APPROVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isLoading: false,
        successRequestApproveCampaign: false,
        errRequestApprove: action.message
      }
    case pack.COPY_CAMPAIGN_FAILURE:
      return {
        ...state,
        successCopyCampaign: false,
        errCopyCampaign: action.message
      };
    case pack.APPROVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        successApproveCampaign: false,
        errApproveCampaign: action.message
      };
    case pack.DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        successDeleteCampaign: false,
        errDeleteCampaign: action.message
      };
    case pack.SYNC_REPORT_START:
      return {
        ...state,
        successSyncCampaign: null,
        isLoadingSync: true,
      };
    case pack.SYNC_REPORT_FAILURE:
      return {
        ...state,
        successSyncCampaign: false,
        isLoadingSync: false,
      };
    case pack.SYNC_REPORT_SUCCESS:
      return {
        ...state,
        successSyncCampaign: true,
        isLoadingSync: false,
      };
    case pack.FOR_CONTROL_START:
      return {
        ...state,
        isLoadingForControl: true,
        successForControl: null,
        exportUrl: ''
      };
    case pack.FOR_CONTROL_FAILURE:
      return {
        ...state,
        isLoadingForControl: false,
        successForControl: false,
        exportUrl: '',
      };
    case pack.FOR_CONTROL_SUCCESS:
      return {
        ...state,
        isLoadingForControl: false,
        successForControl: true,
        exportUrl: action.url,
      };
    default:
      return state;
  }
};

export default campaignReducer;
