export default {
  UPDATE_HIGHLIGHT_ITEMS: 'UPDATE_HIGHLIGHT_ITEMS',

  FETCH_LIST_SUBSCRIPTION_START: 'FETCH_LIST_SUBSCRIPTION_START',
  FETCH_LIST_SUBSCRIPTION_FAILURE: 'FETCH_LIST_SUBSCRIPTION_FAILURE',
  FETCH_LIST_SUBSCRIPTION_SUCCESS: 'FETCH_LIST_SUBSCRIPTION_SUCCESS',

  FETCH_SUBSCRIPTION_DETAIL_START: 'FETCH_SUBSCRIPTION_DETAIL_START',
  FETCH_SUBSCRIPTION_DETAIL_FAILURE: 'FETCH_SUBSCRIPTION_DETAIL_FAILURE',
  FETCH_SUBSCRIPTION_DETAIL_SUCCESS: 'FETCH_SUBSCRIPTION_DETAIL_SUCCESS',

  FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_START: 'FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_START',
  FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_FAILURE: 'FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_FAILURE',
  FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_SUCCESS: 'FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_SUCCESS',

  FETCH_LIST_POS_ORDER_START: 'FETCH_LIST_POS_ORDER_START',
  FETCH_LIST_POS_ORDER_FAILURE: 'FETCH_LIST_POS_ORDER_FAILURE',
  FETCH_LIST_POS_ORDER_SUCCESS: 'FETCH_LIST_POS_ORDER_SUCCESS',

  RESET_SUBSCRIPTION_DETAIL: 'RESET_SUBSCRIPTION_DETAIL'
};
