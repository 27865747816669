export default {
  START_FETCH_USER: 'START_FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  START_ADD_USER: 'START_ADD_USER',
  POST_A_FEEDBACK_SUCCESS: 'POST_A_FEEDBACK_SUCCESS',
  GET_LIST_FEEDBACK_SUCCESS: 'GET_LIST_FEEDBACK_SUCCESS',
  GET_MORE_LIST_FEEDBACK_SUCCESS: 'GET_MORE_LIST_FEEDBACK_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  START_FETCH_USER_ONE: 'START_FETCH_USER_ONE',
  FETCH_USER_ONE_SUCCESS: 'FETCH_USER_ONE_SUCCESS',
  START_UPDATE_USER: 'START_UPDATE_USER',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  START_FETCH_TERM: 'START_FETCH_TERM',
  FETCH_TERM_FAILURE: 'FETCH_TERM_FAILURE',
  FETCH_TERM_SUCCESS: 'FETCH_TERM_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  START_LOGOUT: 'START_LOGOUT',
  START_FETCH_USER_HISTORY: 'START_FETCH_USER_HISTORY',
  FETCH_USER_HISTORY_SUCCESS: 'FETCH_USER_HISTORY_SUCCESS',
  FETCH_USER_HISTORY_FAILURE: 'FETCH_USER_HISTORY_FAILURE',
  START_UPGRADE_PRO: 'START_UPGRADE_PRO',
  UPGRADE_PRO_SUCCESS: 'UPGRADE_PRO_SUCCESS',
  UPGRADE_PRO_FAILURE: 'UPGRADE_PRO_FAILURE',
  START_DOWNGRADE_PRO: 'START_DOWNGRADE_PRO',
  DOWNGRADE_PRO_SUCCESS: 'DOWNGRADE_PRO_SUCCESS',
  DOWNGRADE_PRO_FAILURE: 'DOWNGRADE_PRO_FAILURE',
  RESET_STATUS_DOWNGRADE_PRO: 'RESET_STATUS_DOWNGRADE_PRO',
  // package
  START_FETCH_PACKAGES: 'START_FETCH_PACKAGES',
  FETCH_PACKAGES_SUCCESS: 'FETCH_PACKAGES_SUCCESS',
  FETCH_PACKAGES_FAILURE: 'FETCH_PACKAGES_FAILURE',
  // user subscription
  START_FETCH_USER_SUBSCRIPTION: 'START_FETCH_USER_SUBSCRIPTION',
  FETCH_USER_SUBSCRIPTION_SUCCESS: 'FETCH_USER_SUBSCRIPTION_SUCCESS',
  FETCH_USER_SUBSCRIPTION_FAILURE: 'FETCH_USER_SUBSCRIPTION_FAILURE',
  // user domain
  START_FETCH_USER_DOMAIN: 'START_FETCH_USER_DOMAIN',
  FETCH_USER_DOMAIN_SUCCESS: 'FETCH_USER_DOMAIN_SUCCESS',
  FETCH_USER_DOMAIN_FAILURE: 'FETCH_USER_DOMAIN_FAILURE',
  START_FETCH_BUSINESS_BY_USER: 'START_FETCH_BUSINESS_BY_USER',
  FETCH_BUSINESS_BY_USER_SUCCESS: 'FETCH_BUSINESS_BY_USER_SUCCESS',
  FETCH_BUSINESS_BY_USER_FAILURE: 'FETCH_BUSINESS_BY_USER_FAILURE',
  START_FETCH_USER_DOMAIN_HISTORIES: 'START_FETCH_USER_DOMAIN_HISTORIES',
  FETCH_USER_DOMAIN_HISTORIES_SUCCESS: 'FETCH_USER_DOMAIN_HISTORIES_SUCCESS',
  FETCH_USER_DOMAIN_HISTORIES_FAILURE: 'FETCH_USER_DOMAIN_HISTORIES_FAILURE',
  START_FETCH_DOMAINS: 'START_FETCH_DOMAINS',
  FETCH_DOMAINS_SUCCESS: 'FETCH_DOMAINS_SUCCESS',
  FETCH_DOMAINS_FAILURE: 'FETCH_DOMAINS_FAILURE',
  // user partner
  START_FETCH_MASSUPLOAD_PARTNER: 'START_FETCH_MASSUPLOAD_PARTNER',
  FETCH_MASSUPLOAD_PARTNER_SUCCESS: 'FETCH_MASSUPLOAD_PARTNER_SUCCESS',
  FETCH_MASSUPLOAD_PARTNER_FAILURE: 'FETCH_MASSUPLOAD_PARTNER_FAILURE',
  START_FETCH_MASSUPLOAD_PARTNER_FAILED: 'START_FETCH_MASSUPLOAD_PARTNER_FAILED',
  FETCH_MASSUPLOAD_PARTNER_FAILED_SUCCESS: 'FETCH_MASSUPLOAD_PARTNER_FAILED_SUCCESS',
  FETCH_MASSUPLOAD_PARTNER_FAILED_FAILURE: 'FETCH_MASSUPLOAD_PARTNER_FAILED_FAILURE',
  START_FETCH_USER_PARTNER_BY_PHONE: 'START_FETCH_USER_PARTNER_BY_PHONE',
  FETCH_USER_PARTNER_BY_PHONE_SUCCESS: 'FETCH_USER_PARTNER_BY_PHONE_SUCCESS',
  FETCH_USER_PARTNER_BY_PHONE_FAILURE: 'FETCH_USER_PARTNER_BY_PHONE_FAILURE',
  // User droppii
  START_FETCH_USERS_DROPPII: 'START_FETCH_USERS_DROPPII',
  FETCH_USERS_DROPPII_SUCCESS: 'FETCH_USERS_DROPPII_SUCCESS',
  FETCH_USERS_DROPPII_FAILURE: 'FETCH_USERS_DROPPII_FAILURE',
  //reset users
  RESET_USERS: 'RESET_USERS',
  //reset user detail
  RESET_USER_DETAIL: 'RESET_USER_DETAIL',
  //reset business by user
  RESET_BUSINESS_BY_USER: 'RESET_BUSINESS_BY_USER',
  // subscription history by user
  START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER: 'START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER',
  FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE: 'FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE',
  FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS: 'FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS'
};
