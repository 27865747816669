// ----------------------------------------------------------------------

export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [theme.breakpoints.up('md')]: {
            maxWidth: 10000
          }
        }
      }
    }
  };
}
