import axios from 'axios';
import queryString from 'query-string';

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  // headers: {
  //   'content-type': 'application/json;charset=UTF-8',
  //   // 'Authorization': name=>name
  // },
  paramsSerializer: params => queryString.stringify(params, {arrayFormat: 'bracket'}),
});

axiosClient.interceptors.response.use((response) => {
  if (response && response.data) {
    console.log(response)
    return response; 
  }

  return response;
}, (error) => {
  // Handle errors
  throw error;
});

export default axiosClient;