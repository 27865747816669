export default {
  START_FETCH_NOTI: 'START_FETCH_NOTI',
  FETCH_NOTI_SUCCESS: 'FETCH_NOTI_SUCCESS',
  FETCH_NOTI_FAILURE: 'FETCH_NOTI_FAILURE',
  START_ADD_NOTI: 'START_ADD_NOTI',
  POST_A_FEEDBACK_SUCCESS: 'POST_A_FEEDBACK_SUCCESS',
  GET_LIST_FEEDBACK_SUCCESS: 'GET_LIST_FEEDBACK_SUCCESS',
  GET_MORE_LIST_FEEDBACK_SUCCESS: 'GET_MORE_LIST_FEEDBACK_SUCCESS',
  ADD_NOTI_FAILURE: 'ADD_NOTI_FAILURE',
  ADD_NOTI_SUCCESS: 'ADD_NOTI_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  START_FETCH_NOTI_ONE: 'START_FETCH_NOTI_ONE',
  FETCH_NOTI_ONE_FAILURE: 'FETCH_NOTI_ONE_FAILURE',
  FETCH_NOTI_ONE_SUCCESS: 'FETCH_NOTI_ONE_SUCCESS',
  START_UPDATE_NOTI: 'START_UPDATE_NOTI',
  UPDATE_NOTI_FAILURE: 'UPDATE_NOTI_FAILURE',
  UPDATE_NOTI_SUCCESS: 'UPDATE_NOTI_SUCCESS',
  SEND_NOTI_SUCCESS: 'SEND_NOTI_SUCCESS',
  START_SEND_NOTI: 'START_SEND_NOTI',
  SEND_NOTI_FAILURE: 'SEND_NOTI_FAILURE',
  START_FETCH_SMART_TAG_TYPE: 'START_FETCH_SMART_TAG_TYPE',
  FETCH_SMART_TAG_TYPE_SUCCESS: 'FETCH_SMART_TAG_TYPE_SUCCESS',
  FETCH_SMART_TAG_TYPE_FAILURE: 'FETCH_SMART_TAG_TYPE_FAILURE',
  START_FETCH_DEEPLINKS: 'START_FETCH_DEEPLINKS',
  FETCH_DEEPLINKS_SUCCESS: 'FETCH_DEEPLINKS_SUCCESS',
  FETCH_DEEPLINKS_FAILURE: 'FETCH_DEEPLINKS_FAILURE'
};
