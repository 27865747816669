import posType from './action.type/pos.types';
import { BASE_URL } from '../../config';
import { getData } from '../api/api.service';

export const API_CREATE_ORDER = '/fo-subscription/api/v1/admin-portal/billing/create';
export const API_UPDATE_ORDER = '/finan-subscription-plan/api/v1/subscription-order/admin-portal/update-detail';
export const API_CREATE_BUSINESS = '/finan-subscription-plan/api/v1/subscription-order/admin-portal/create-business';
export const API_CREATE_FREE_TRIAL = '/fo-subscription/api/v1/admin-portal/billing/create-trial';
export const API_LIST_SUBSCRIPTION = '/fo-subscription/api/v1/admin-portal/package/get-list';
export const API_UPDATE_SUBSCRIPTION = '/finan-subscription-plan/api/v1/subscription-package/update-pricing';
export const API_POS_ORDER_LIST = '/fo-subscription/api/v1/admin-portal/billing/get-list';

export const fetchSubscriptionList = (params, token) => async (dispatch) => {
  dispatch({ type: posType.FETCH_LIST_SUBSCRIPTION_START });
  const data = await getData(
    BASE_URL + API_LIST_SUBSCRIPTION,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: posType.FETCH_LIST_SUBSCRIPTION_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  dispatch({
    type: posType.FETCH_LIST_SUBSCRIPTION_SUCCESS,
    data: data?.data?.data || [],
    params: params?.type
  });
};

export const fetchSubscriptionDetail = (params, id, token) => async (dispatch) => {
  dispatch({ type: posType.FETCH_SUBSCRIPTION_DETAIL_START });
  const data = await getData(
    BASE_URL + '/finan-subscription-plan/api/v1/subscription-package/get-one/' + id,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: posType.FETCH_SUBSCRIPTION_DETAIL_FAILURE,
        message: error.message
      })
  );
  dispatch({
    type: posType.FETCH_SUBSCRIPTION_DETAIL_SUCCESS,
    data: data?.data?.data || null
  });
};

export const fetchSubscriptionDetailByBiz = (params, token) => async (dispatch) => {
  dispatch({ type: posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_START });
  const data = await getData(
    BASE_URL + '/finan-subscription-plan/api/v1/biz-has-package/admin-portal/get-detail',
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_FAILURE,
        message: error.message
      })
  );
  dispatch({
    type: posType.FETCH_SUBSCRIPTION_DETAIL_BY_BIZ_SUCCESS,
    data: data?.data?.data || null
  });
};

export const fetchPosOrderList = (params, token) => async (dispatch) => {
  dispatch({ type: posType.FETCH_LIST_POS_ORDER_START });
  const data = await getData(
    BASE_URL + API_POS_ORDER_LIST,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: posType.FETCH_LIST_POS_ORDER_FAILURE,
        message: error.message,
        statusError: error?.status
      })
  );
  dispatch({
    type: posType.FETCH_LIST_POS_ORDER_SUCCESS,
    data: { data: data?.data?.data || [], totalPage: data?.data?.meta?.total_pages || 1 }
  });
};
