import user from './action.type/user.type';
import { API_POS_UP, API_TERM_TAXONOMY, API_USER, BASE_URL } from '../../config';
import { catchError, getData, postData, putData } from '../api/api.service';
import { API_POS_ORDER_LIST } from './pos.action';
const API_GET_USER_LIST = '/fo-user/api/v1/admin-portal/user/get-list';
const API_USER_V2 = '/fo-user/api/v1/admin-portal/user/get-one';
export const API_GET_BUSINESS_BY_USER = '/fo-business/api/v1/admin-portal/business/get-list-own';
export const API_COUNT_CHANGE_DOMAIN_USER = `/${API_USER}/v1/change-domain`;
export const API_UPDATE_USER_ROLE = API_USER_V2 + '/set-role';
export const API_LOGOUT_ALL_DEVICES = '/finan-user/api/v1/user/end-session';
export const API_LIMIT_ORDERS = '/finan-subscription-plan/api/v1/user-subscription/admin-portal/update-metadata';
export const API_LIMIT_ORDERS_BY_BIZ =
  '/finan-subscription-plan/api/v1/biz-has-package/admin-portal/update-order-limit';
const API_DOWNGRADE_PRO = '/finan-subscription-plan/api/v1/user-has-package/admin-portal/update';
export const API_CREATE_USER_PARTNER = '/finan-user/api/v1/user-has-platform/create';
export const API_MASSUPLOAD_USER_PARTNER = '/finan-user/api/v1/user-has-platform/import';
const API_MASSUPLOAD_UPGRADE_DROPPII = '/finan-subscription-plan/api/v1/user-subscription/admin-portal/mass-register';
const API_LOGOUT = `/fo-user/api/v1/auth/logout`;
export const API_USER_REF = '/finan-loyalty/api/v1/membership/admin/update-refer';
export const API_USERS_DOMAIN = API_USER_V2 + '/get-list?count_business=true&has_partner=true';

export const fetchUserList = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USER });
  const response = await getData(
    BASE_URL + API_GET_USER_LIST,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_USER_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_USER_SUCCESS,
    data: response?.data?.data?.data || response?.data?.data || [],
    success: response?.data?.status === 200 ? true : false,
    message: response?.data?.message,
    lastpage: response?.data?.data?.meta?.total_pages || response?.data?.meta?.total_pages || 1
  });
};

export const fetchLogout = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_LOGOUT });
  const data = await postData(
    BASE_URL + API_LOGOUT,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_USER_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.LOGOUT_SUCCESS,
    // data: data?.data?.data,
    success: data?.data?.status === 200 ? true : false,
    message: data?.data?.message
    // lastpage: data?.headers['x-last-page']
  });
};

export const fetchTermUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_TERM });
  const data = await getData(
    BASE_URL + '/' + API_TERM_TAXONOMY,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_TERM_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_TERM_SUCCESS,
    data: data?.data?.data,
    success: data?.data?.status === 200 ? true : false,
    message: data?.data?.message,
    lastpage: data?.headers['x-last-page']
  });
};

export const fetchUserOne = (params, id, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USER_ONE });
  const data = await getData(
    BASE_URL + API_USER_V2 + '/' + id,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: user.FETCH_USER_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  dispatch({
    type: user.FETCH_USER_ONE_SUCCESS,
    data: data?.data?.data,
    success: data?.data?.status === 200 ? true : false,
    message: data?.data?.message
  });
};

export const addUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_ADD_USER });
  const data = await postData(
    BASE_URL + API_GET_USER_LIST,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: user.ADD_USER_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  if (data) {
    dispatch({
      type: user.ADD_USER_SUCCESS,
      data: data?.data?.data,
      success: data?.data?.status === 200 ? true : false,
      message: data?.data?.message
    });
  }
};

export const updateUser = (params, id, token) => async (dispatch) => {
  dispatch({ type: user.START_UPDATE_USER });
  const data = await putData(
    BASE_URL + API_USER_V2 + '/update/' + id,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: user.UPDATE_USER_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  dispatch({
    type: user.UPDATE_USER_SUCCESS,
    data: data?.data?.data,
    success: data?.status === 200 ? true : false,
    message: data?.data?.message
  });
};

export const fetchUserHistory = (params, id, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USER_HISTORY });
  const data = await getData(
    `${BASE_URL}/finan-user/api/v1/registration-pro/admin-portal/user/${id}`,
    params,
    token,
    null,
    (error) =>
      dispatch({
        type: user.FETCH_USER_HISTORY_FAILURE,
        message: error.message,
        error: error.status
      })
  );
  dispatch({
    type: user.FETCH_USER_HISTORY_SUCCESS,
    data: data?.data?.data,
    success: data?.data?.status === 200 ? true : false,
    message: data?.data?.message
  });
};

export const fetchUserHistoryV2 = (params, id, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USER_HISTORY });
  const data = await getData(
    `${BASE_URL}/finan-subscription-plan/api/v1/user-tracking-package/admin-portal/tracking?user_id=${id}`,
    params,
    token,
    null,
    (error) =>
      dispatch({
        type: user.FETCH_USER_HISTORY_FAILURE,
        message: error.message,
        error: error.status
      })
  );
  dispatch({
    type: user.FETCH_USER_HISTORY_SUCCESS,
    data: data?.data?.data || null,
    success: data?.data?.data ? true : false,
    message: data?.data?.message
  });
};

export const fetchPackages = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_PACKAGES });
  const data = await getData(
    `${BASE_URL}/finan-subscription-plan/api/v1/sub-package/admin/get-list?user_id=${params.user_id}`,
    params,
    token,
    null,
    (error) =>
      dispatch({
        type: user.FETCH_PACKAGES_FAILURE,
        message: error.message,
        error: error.status
      })
  );
  dispatch({
    type: user.FETCH_PACKAGES_SUCCESS,
    data: data?.data?.data || null,
    success: data?.data?.data ? true : false,
    message: data?.data?.message
  });
};

export const upgradePackageUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_UPGRADE_PRO });
  const data = await postData(
    `${BASE_URL}/finan-subscription-plan/api/v1/user-has-package/admin-portal/register`,
    params,
    token,
    null,
    (error) =>
      dispatch({
        type: user.UPGRADE_PRO_FAILURE,
        message: error.message,
        error: error.status
      })
  );
  if (data) {
    dispatch({
      type: user.UPGRADE_PRO_SUCCESS,
      data: data?.data?.data,
      success: data?.data?.data ? true : false,
      message: data?.data?.message
    });
  }
};

export const downgradeProUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_DOWNGRADE_PRO });
  const data = await putData(
    BASE_URL + API_DOWNGRADE_PRO,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: user.DOWNGRADE_PRO_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  if (data) {
    dispatch({
      type: user.DOWNGRADE_PRO_SUCCESS,
      data: data?.data?.data,
      success: data?.data?.status === 200 ? true : false,
      message: data?.data?.message
    });
  }
};

export const fetchBusinessByUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_BUSINESS_BY_USER });
  const response = await getData(
    BASE_URL + API_GET_BUSINESS_BY_USER + `/${params.id}`,
    null,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_BUSINESS_BY_USER_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_BUSINESS_BY_USER_SUCCESS,
    data: response?.data?.data || [],
    success: response?.data?.status === 200 ? true : false,
    message: response?.data?.message
  });
};

export const fetchDomains = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_DOMAINS });
  const response = await getData(
    BASE_URL + `/finan-business/api/v1/custom-domain/get-last-domain`,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_DOMAINS_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_DOMAINS_SUCCESS,
    data: response?.data?.data,
    success: response?.data?.status === 200 ? true : false,
    message: response?.data?.message
  });
};

export const fetchUserSubcription = (params, id, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USER_SUBSCRIPTION });
  const response = await getData(
    BASE_URL + `/finan-subscription-plan/api/v1/user-subscription/admin-portal/get-one/${id}`,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_USER_SUBSCRIPTION_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_USER_SUBSCRIPTION_SUCCESS,
    data: response?.data?.data
  });
};

async function createMassUploadLink({ name, media_type }) {
  const params = { name, media_type };
  return await postData(
    `${BASE_URL}/finan-mass-upload/api/v1/mass-upload/pre-up`,
    params,
    null,
    null,
    //FAILURE
    (error) => {
      throw error;
    }
  );
}

async function commitUploadLink({ name, media_type }) {
  const params = { name, media_type };
  return await postData(
    `${BASE_URL}/${API_POS_UP}`,
    params,
    null,
    null,
    //FAILURE
    (error) => {
      throw error;
    }
  );
}

//massupload upgrade package for droppii
export async function uploadUpgradePackageForDroppiiFile(file) {
  const response = await createMassUploadLink({
    media_type: 'excel',
    name: file.name
  });
  const { file_name, file_name_origin, link } = response.data.data;

  return await uploadUpgradePackageForDroppii(file, link, file_name, file_name_origin);
}

async function uploadUpgradePackageForDroppii(file, uploadUrl, file_name, file_name_origin) {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.setRequestHeader('Content-Length', file.size ?? 0);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.send(file);
    xhr.onload = async function () {
      if (this.status === 200) {
        const params = {
          media_type: 'excel',
          name: file_name
        };
        const posUp = await commitUploadLink(params);
        if (posUp?.data?.data?.upload_url) {
          return await postData(
            `${BASE_URL}${API_MASSUPLOAD_UPGRADE_DROPPII}`,
            {
              file_name,
              file_name_origin,
              link: posUp?.data?.data?.upload_url
              // file_type: 'EXCEL',
              // limit: 0
            },
            null,
            null,
            //FAILURE
            (error) => {
              throw error;
            }
          );
        }
        return true;
      }
    };
    return true;
  } catch (error) {
    catchError(error, callbackError);
  }
}

// massupload create partner
export async function uploadCreatePartnerFile(file) {
  const response = await createMassUploadLink({
    media_type: 'excel',
    name: file.name
  });
  const { file_name, file_name_origin, link } = response.data.data;

  return await uploadCreatePartner(file, link, file_name, file_name_origin);
}

async function uploadCreatePartner(file, uploadUrl, file_name, file_name_origin) {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.setRequestHeader('Content-Length', file.size ?? 0);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.send(file);
    xhr.onload = async function () {
      if (this.status === 200) {
        const params = {
          media_type: 'excel',
          name: file_name
        };
        const posUp = await commitUploadLink(params);
        if (posUp?.data?.data?.upload_url) {
          return await postData(
            `${BASE_URL}${API_MASSUPLOAD_USER_PARTNER}`,
            {
              file_name,
              file_name_origin,
              link: posUp?.data?.data?.upload_url,
              file_type: 'EXCEL',
              limit: 0
            },
            null,
            null,
            //FAILURE
            (error) => {
              throw error;
            }
          );
        }
        return true;
      }
    };
    return true;
  } catch (error) {
    catchError(error, callbackError);
  }
}

export const fetchUsersDroppii = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_USERS_DROPPII });
  const response = await getData(
    BASE_URL + `/finan-user/api/v1/user/admin/partner/get-list?state=all`,
    params,
    token,
    null,
    //FAILURE
    (error) => {
      dispatch({
        type: user.FETCH_USERS_DROPPII_FAILURE,
        message: error.message,
        statusError: error.status
      });
    }
  );
  dispatch({
    type: user.FETCH_USERS_DROPPII_SUCCESS,
    data: response?.data?.data?.data,
    success: response?.data?.status === 200 ? true : false,
    message: response?.data?.message,
    meta: {
      totalPages: response?.data?.data?.meta?.total_pages || 1,
      totalUsers: response?.data?.data?.meta?.total_users || 0,
      totalExpired: response?.data?.data?.meta?.total_expired || 0,
      totalUnexpired: response?.data?.data?.meta?.total_unexpired || 0
    }
  });
};

export const fetchSubscriptionsHistoryByUser = (params, token) => async (dispatch) => {
  dispatch({ type: user.START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER });
  const data = await getData(
    BASE_URL + API_POS_ORDER_LIST,
    params,
    token,
    null,
    //FAILURE
    (error) =>
      dispatch({
        type: user.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE,
        message: error.message,
        statusError: error.status
      })
  );
  dispatch({
    type: user.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS,
    data: data?.data?.data || []
  });
};
