export default {
  START_ADD_ROLE: 'START_ADD_ROLE',
  ADD_ROLE_FAILURE: 'ADD_ROLE_FAILURE',
  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  START_CREATE_ROLE: 'START_CREATE_ROLE',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  START_FETCH_DETAIL_ROLE: 'START_FETCH_DETAIL_ROLE',
  FETCH_DETAIL_ROLE_FAILURE: 'FETCH_DETAIL_ROLE_FAILURE',
  FETCH_DETAIL_ROLE_SUCCESS: 'FETCH_DETAIL_ROLE_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  START_FETCH_ROLE_ONE: 'START_FETCH_ROLE_ONE',
  FETCH_ROLE_ONE_SUCCESS: 'FETCH_ROLE_ONE_SUCCESS',
  START_UPDATE_ROLE: 'START_UPDATE_ROLE',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  START_FETCH_ROLE: 'START_FETCH_ROLE',
  FETCH_ROLE_FAILURE: 'FETCH_ROLE_FAILURE',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  RESET_UPDATE: 'RESET_UPDATE'
};
