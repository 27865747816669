export default {
    FETCH_LIST_PACKAGE_START: 'FETCH_LIST_PACKAGE_START',
    FETCH_LIST_PACKAGE_FAILURE: 'FETCH_LIST_PACKAGE_FAILURE',
    FETCH_LIST_PACKAGE_SUCCESS: 'FETCH_LIST_PACKAGE_SUCCESS',

    FETCH_PACKAGE_DETAIL_START: 'FETCH_PACKAGE_DETAIL_START',
    FETCH_PACKAGE_DETAIL_FAILURE: 'FETCH_PACKAGE_DETAIL_FAILURE',
    FETCH_PACKAGE_DETAIL_SUCCESS: 'FETCH_PACKAGE_DETAIL_SUCCESS',

    CREATE_PACKAGE_START: 'CREATE_PACKAGE_START',
    CREATE_PACKAGE_FAILURE: 'CREATE_PACKAGE_FAILURE',
    CREATE_PACKAGE_SUCCESS: 'CREATE_PACKAGE_SUCCESS',

    UPDATE_PACKAGE_START: 'UPDATE_PACKAGE_START',
    UPDATE_PACKAGE_FAILURE: 'UPDATE_PACKAGE_FAILURE',
    UPDATE_PACKAGE_SUCCESS: 'UPDATE_PACKAGE_SUCCESS',

    DELETE_PACKAGE_START: 'DELETE_PACKAGE_START',
    DELETE_PACKAGE_FAILURE: 'DELETE_PACKAGE_FAILURE',
    DELETE_PACKAGE_SUCCESS: 'DELETE_PACKAGE_SUCCESS',
}
