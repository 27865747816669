import pack from '../action/action.type/package.types';

const initialState = {
    isLoading: false,
    data: [],
    packageInfo: null,
    totalItems: 0,
    totalPage: 1,
    successCreatePackage: null,
    errCreatePackage: null,
};

const packageReducer = (state = initialState, action) => {
    switch (action.type) {
        // START---------------------------------
        case pack.FETCH_LIST_PACKAGE_START:
            return {
                ...state,
                isLoading: true,
                data: [],
                totalPage: 1,
                totalItems: 0
            }

        case pack.FETCH_PACKAGE_DETAIL_START:
            return {
                ...state,
                isLoading: true,
                packageInfo: null
            }

        case pack.CREATE_PACKAGE_START:
        case pack.UPDATE_PACKAGE_START:
            return {
                ...state,
                isLoading: true,
                successCreatePackage: null,
                errCreatePackage: null
            }

        // success----------
        case pack.FETCH_LIST_PACKAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                totalItems: action.totalItems,
                totalPage: action.totalPage
            };

        case pack.FETCH_PACKAGE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                packageInfo: action.data
            }

        case pack.CREATE_PACKAGE_SUCCESS:
        case pack.UPDATE_PACKAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successCreatePackage: true,
            }

      // error----------
        case pack.FETCH_LIST_PACKAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        case pack.FETCH_PACKAGE_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                packageInfo: null,
            }

        case pack.CREATE_PACKAGE_FAILURE:
        case pack.UPDATE_PACKAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                successCreatePackage: false,
                errCreatePackage: action.message
            }

        default:
            return state;
    }
};

export default packageReducer;
