import pack from '../action/action.type/znsCampaign.types';

const initialState = {
  isLoadingGetList: null,
  dataList: [],
  totalItems: 0,
  totalPage: 1,
};

const znsCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    // fetch list
    case pack.FETCH_LIST_ZNS_CAMPAIGN_START:
      return {
        ...state,
        isLoadingGetList: true,
        dataList: [],
        totalItems: 0,
        totalPage: 1
      };

    case pack.FETCH_LIST_ZNS_CAMPAIGN_FAILURE:
      return {
        ...state,
        isLoadingGetList: false,
        dataList: [],
        totalItems: 0,
        totalPage: 1
      };

    case pack.FETCH_LIST_ZNS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoadingGetList: false,
        dataList: action.data,
        totalItems: action.totalItems,
        totalPage: action.totalPage
      };


    default:
      return state;
  }
};

export default znsCampaignReducer;
