export function getDeviceName() {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }
  return 'desktop';
}

export function getOperatingSystem() {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) return 'Android';

    if (/iPad|iPhone|iPod/.test(userAgent)) return 'iOS';

    if (userAgent.indexOf('Win') !== -1) return 'Windows';

    if (userAgent.indexOf('Mac') !== -1) return 'MacOS';

    if (userAgent.indexOf('Linux') !== -1) return 'Linux';
  }

  return 'unknown';
}

export const isExpired = (expire_time) => {
  if (Date.parse(expire_time) < Date.parse(new Date().toString())) {
    return true;
  }
  return false;
};

export function formatPhoneWithZero(value) {
  return value ? value.replace('+84', '0') : '';
}

export function formatCurrency(value) {
  const formatter = new Intl.NumberFormat('vi-US');
  const newValue = parseInt(value.toString(), 10);
  return `${formatter.format(newValue)}`;
}

export function download(arrayBuffer, filename) {
  const byteArray = new Uint8Array(arrayBuffer);
  const newBlob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const url = window.URL.createObjectURL(newBlob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function getLocaleCode() {
  const code = navigator?.language || navigator?.languages?.[0] || 'vi_VN';
  return code.replace('-', '_');
}

export function removeDuplicates(array, key) {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
}

export function renderUserName(user) {
  return `${user?.first_name || ''} ${user?.last_name || ''}`;
}

export function renderLanguage(user) {
  if (!user || !user?.default_lang_code || user?.default_lang_code === 'vi') return 'Vietnamese';
  if (user?.default_lang_code === 'en') return 'English';
}

export function generateLocation(location) {
  if (!location) return '';
  const dataArray = Object.values({
    ward_name: location.ward_name || location?.area_level1_name,
    district_name: location.district_name || location?.area_level2_name,
    province_name: location.province_name || location?.area_level3_name
  }).filter((value) => value);
  const result = dataArray.join(', ');
  const detail_address = location?.address || location.detail_address;
  return detail_address ? `${detail_address}, ${result}` : result;
}
