import React from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 62;

const RootStyle = styled(AppBar)(({ isPOS, theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: isPOS ? '100%' : `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ isPOS, onOpenSidebar }) {
  const navigate = useNavigate();
  return (
    <RootStyle isPOS={isPOS}>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon={'eva:menu-2-fill'} />
          </IconButton>
        </MHidden>
        {isPOS && (
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton
              size="small"
              onClick={() => navigate(-1)}
              sx={{ fontSize: 40, cursor: 'pointer', color: (theme) => theme.palette.grey[700] }}
            >
              <Iconify icon={'eva:chevron-left-fill'} />
            </IconButton>
            <Typography variant="h6" color="InfoText" fontWeight={600}>
              POS
            </Typography>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Box textAlign="right" mr={4}>
          <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.primary.main }}>
            Ctrl + Shift + R (Window)
          </Typography>
          <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.primary.main }}>
            Cmd + Shift + R (Mac)
          </Typography>
        </Box>

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
