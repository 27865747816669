import instance from 'axios';
import { api, BASE_URL } from 'src/config';
import { getData, postData } from '../redux/api/api.service';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../utils/constant';

class AuthService {
  loginAccount = async (body) => {
    const response = await postData(`${BASE_URL}/fo-user/api/v1/admin-portal/auth/login`, body, null, null, (error) => {
      throw new Error(
        error?.message?.content || error?.message || error?.error?.detail || error?.detail || 'Xảy ra lỗi'
      );
    });
    return response?.data;
  };

  getUserInfo = async () => {
    const response = await getData(`${BASE_URL}/fo-user/api/v1/user/get-current`, null, null, null, (error) => {
      throw new Error(
        error?.message?.content || error?.message || error?.error?.detail || error?.detail || 'Xảy ra lỗi'
      );
    });
    return response?.data;
  };

  renewToken = async () => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (!refreshToken) {
      throw new Error('NO_REFRESH_TOKEN');
    }

    const { token } = await postData(`${BASE_URL}/fo-user/api/v1/auth/renew-token`, {
      refresh_token: refreshToken
    });

    return token;
  };
}

const authService = new AuthService();

export default authService;
