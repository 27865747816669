import { BASE_URL } from 'src/config';
import { getData } from '../redux/api/api.service';

class BusinessService {
  getCurrentBusiness = async () => {
    const response = await getData(`${BASE_URL}/fo-business/api/v1/business/get-current`, null, null, null, (error) => {
      throw new Error(
        error?.message?.content || error?.message || error?.error?.detail || error?.detail || 'Xảy ra lỗi'
      );
    });
    return response?.data;
  };
}

const businessService = new BusinessService();

export default businessService;
