import roletype from '../action/action.type/role.types';
const initialState = {
  isLoading: false,
  error: false,
  data: [],
  success: null,
  message: '',
  paging: null,
  dataadd: [],
  totalPage: 1,
  successadd: null,
  messageadd: null,
  dataone: [],
  successone: null,
  dataupdate: [],
  successupdate: null,
  lastpage: 1,
  successterm: null,
  messageterm: null,
  successlogout: null,
  messagelogout: null
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roletype.START_FETCH_ROLE:
      return { ...state, isLoading: true, data: [], error: false, successterm: null, messageterm: null };
    case roletype.START_CREATE_ROLE:
      return { ...state, isLoading: true, error: false, successadd: null, messageadd: null };
    case roletype.START_FETCH_DETAIL_ROLE:
      return { ...state, isLoading: true, dataone: [], error: false, successone: null, message: null };

    case roletype.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.data,
        totalPage: action.totalPage,
        success: action.success,
        message: action.message
        // lastpage: action.lastpage
      };
    case roletype.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        successadd: action.successadd,
        messageadd: action.messageadd
      };
    case roletype.FETCH_DETAIL_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataone: action.data,
        successone: action.success,
        message: action.message
      };
    case roletype.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        successupdate: action.success,
        messageupdate: action.message
      };
    case roletype.RESET_UPDATE:
      return {
        ...state,
        isLoading: false,
        error: false,
        successupdate: action.success,
        messageupdate: action.message
      };
    case roletype.FETCH_ROLE_FAILURE:
      return { ...state, isLoading: false, error: true, dataterm: [], messageterm: action.message, successterm: false };
    case roletype.CREATE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        messageadd: action.messageadd,
        successadd: false
      };
    case roletype.FETCH_DETAIL_ROLE_FAILURE:
      return { ...state, isLoading: false, successone: false, error: true, dataone: [], message: action.message };
    case roletype.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        messageupdate: action.message,
        successupdate: false
      };

    default:
      return state;
  }
};

export default roleReducer;
