export default {
  // subscriptions
  START_FETCH_SUBSCRIPTIONS_BY_BIZ: 'START_FETCH_SUBSCRIPTIONS_BY_BIZ',
  FETCH_SUBSCRIPTIONS_BY_BIZ_FAILURE: 'FETCH_SUBSCRIPTIONS_BY_BIZ_FAILURE',
  FETCH_SUBSCRIPTIONS_BY_BIZ_SUCCESS: 'FETCH_SUBSCRIPTIONS_BY_BIZ_SUCCESS',

  // subscription history
  START_FETCH_SUBSCRIPTIONS_HISTORY: 'START_FETCH_SUBSCRIPTIONS_HISTORY',
  FETCH_SUBSCRIPTIONS_HISTORY_FAILURE: 'FETCH_SUBSCRIPTIONS_HISTORY_FAILURE',
  FETCH_SUBSCRIPTIONS_HISTORY_SUCCESS: 'FETCH_SUBSCRIPTIONS_HISTORY_SUCCESS',

  // subscription history by business
  START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER: 'START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER',
  FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE: 'FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE',
  FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS: 'FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS',

  // subscription detail
  START_FETCH_SUBSCRIPTION_DETAIL_HISTORY: 'START_FETCH_SUBSCRIPTION_DETAIL_HISTORY',
  FETCH_SUBSCRIPTION_DETAIL_HISTORY_FAILURE: 'FETCH_SUBSCRIPTION_DETAIL_HISTORY_FAILURE',
  FETCH_SUBSCRIPTION_DETAIL_HISTORY_SUCCESS: 'FETCH_SUBSCRIPTION_DETAIL_HISTORY_SUCCESS',

  // order detail
  START_FETCH_ORDER_DETAIL: 'START_FETCH_ORDER_DETAIL',
  FETCH_ORDER_DETAIL_FAILURE: 'FETCH_ORDER_DETAIL_FAILURE',
  FETCH_ORDER_DETAIL_SUCCESS: 'FETCH_ORDER_DETAIL_SUCCESS',

  // business detail
  START_FETCH_BUSINESS_DETAIL: 'START_FETCH_BUSINESS_DETAIL',
  FETCH_BUSINESS_DETAIL_FAILURE: 'FETCH_BUSINESS_DETAIL_FAILURE',
  FETCH_BUSINESS_DETAIL_SUCCESS: 'FETCH_BUSINESS_DETAIL_SUCCESS',

  // reset order detail
  RESET_ORDER_DETAIL: 'RESET_ORDER_DETAIL',

  // reset subscription detail
  RESET_SUBSCRIPTION_DETAIL: 'RESET_SUBSCRIPTION_DETAIL'
};
