import notiCampaignType from '../action/action.type/notiCampaign.types';
const initialState = {
  isLoading: false,
  dataupdate: [],
  error: false,
  successupdate: null,
  messageupdate: null,
  data: [],
  success: null,
  message: '',
  dataResponse: null,
  successadd: null,
  messageadd: null,
  lastpage: 1,
  dataone: null,
  labels: []
};
const notiCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET LIST
    case notiCampaignType.START_FETCH_NOTI_CAMPAIGN_LIST:
      return { ...state, isLoading: true, data: [], error: false, success: null, message: null };
    case notiCampaignType.FETCH_NOTI_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: false,
        success: action.success,
        messages: action.messages,
        lastpage: action.lastpage
      };
    case notiCampaignType.FETCH_NOTI_CAMPAIGN_LIST_FAILURE:
      return { ...state, isLoading: false, data: [], lastpage: 1, success: false, messages: null };

    // GET DETAIL
    case notiCampaignType.START_FETCH_NOTI_CAMPAIGN_ONE:
      return { ...state, isLoading: true, data: [], error: false, success: null, message: null };
    case notiCampaignType.FETCH_NOTI_CAMPAIGN_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataone: action.data,
        error: false,
        success: action.success,
        messages: action.message
      };
    case notiCampaignType.FETCH_NOTI_CAMPAIGN_ONE_FAILURE:
      return { ...state, isLoading: false, dataone: null, success: false, messages: null };

    // CREATE
    case notiCampaignType.START_CREATE_NOTI_CAMPAIGN:
      return { ...state, isLoading: true, error: false, successadd: null, messageadd: null };
    case notiCampaignType.CREATE_NOTI_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataResponse: action.data,
        successadd: action.success,
        messageadd: action.message
      };
    case notiCampaignType.CREATE_NOTI_CAMPAIGN_FAILURE:
      return { ...state, isLoading: false, successadd: false, messageadd: null };

    // UPDATE
    case notiCampaignType.START_UPDATE_NOTI_CAMPAIGN:
      return { ...state, isLoading: true, error: false, successupdate: null, messageupdate: null };
    case notiCampaignType.UPDATE_NOTI_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        successupdate: action.success,
        messageupdate: action.messages
      };
    case notiCampaignType.UPDATE_NOTI_CAMPAIGN_FAILURE:
      return { ...state, isLoading: false, successupdate: false, messageupdate: null };

    // NOTI LABELS
    case notiCampaignType.START_FETCH_NOTI_LABELS:
      return { ...state, labels: [] };
    case notiCampaignType.FETCH_NOTI_LABELS_SUCCESS:
      return {
        ...state,
        labels: action.data
      };
    case notiCampaignType.FETCH_NOTI_LABELS_FAILURE:
      return { ...state, labels: [] };
    default:
      return state;
  }
};

export default notiCampaignReducer;
