import { Buffer } from 'buffer';
import crypto from 'crypto';
import { SECRET_KEY } from 'src/config';

const algorithm = 'aes-256-gcm';
const IV_LEN = 12;
const AUTH_TAG_LEN = 16;

export function encrypt(message) {
  const iv = Buffer.from(crypto.randomBytes(IV_LEN));
  const cipher = crypto.createCipheriv(algorithm, SECRET_KEY, iv, {
    authTagLength: AUTH_TAG_LEN
  });
  const encryptedData = Buffer.concat([cipher.update(message), cipher.final()]);
  const authTag = cipher.getAuthTag();

  const raw = Buffer.concat([iv, encryptedData, authTag]).toString('hex');
  return raw;
}

export const decrypt = (encryptedDataHex) => {
  const IV_LEN = 12;
  const ALGORITHM = 'aes-256-gcm';
  const AUTH_TAG_LEN = 16;

  // Convert the hexadecimal string back to a Buffer
  const encryptedBuffer = Buffer.from(encryptedDataHex, 'hex');

  // Extract the IV, encrypted data, and authentication tag from the concatenated buffer
  const iv = encryptedBuffer.slice(0, IV_LEN);
  const encryptedData = encryptedBuffer.slice(IV_LEN, -AUTH_TAG_LEN);
  const authTag = encryptedBuffer.slice(-AUTH_TAG_LEN);

  // Create a decipher object with the same algorithm, secret key, and IV
  const decipher = crypto.createDecipheriv(ALGORITHM, SECRET_KEY, iv, {
    authTagLength: AUTH_TAG_LEN
  });

  // Set the authentication tag
  decipher.setAuthTag(authTag);

  // Decrypt the data and return it as a Buffer
  const decryptedData = Buffer.concat([decipher.update(encryptedData), decipher.final()]);

  return decryptedData.toString();
};

function base64URLEncode(str) {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function sha256(buffer) {
  return crypto.createHash('sha256').update(buffer).digest();
}

export function generateZaloCodeChallenge() {
  const verifier = base64URLEncode(crypto.randomBytes(32));
  const challenge = base64URLEncode(sha256(verifier));
  return { verifier, challenge };
}
