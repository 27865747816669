// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  finan: {
    notiManagement: path(ROOTS_DASHBOARD, '/noti-management'),
    noti: path(ROOTS_DASHBOARD, '/noti-management/noti'),
    addnoti: path(ROOTS_DASHBOARD, '/noti-management/noti/add'),
    notiCampaign: path(ROOTS_DASHBOARD, '/noti-management/campaign'),
    addnotiCampaign: path(ROOTS_DASHBOARD, '/noti-management/campaign/add'),

    // pos
    pos: path(ROOTS_DASHBOARD, '/pos'),
    subscription: path(ROOTS_DASHBOARD, '/subscription'),
    order: path(ROOTS_DASHBOARD, '/order'),

    // business
    business: path(ROOTS_DASHBOARD, '/business'),

    // user
    userManager: path(ROOTS_DASHBOARD, '/usermanager'),
    user: path(ROOTS_DASHBOARD, '/usermanager/user'),
    useradd: path(ROOTS_DASHBOARD, '/usermanager/add'),
    userBusinessProfile: path(ROOTS_DASHBOARD, 'usermanager/business-profile'),

    customer: path(ROOTS_DASHBOARD, '/customer'),
    banner: path(ROOTS_DASHBOARD, '/banner'),
    banneradd: path(ROOTS_DASHBOARD, '/banner/add'),

    // Other setting
    setting: path(ROOTS_DASHBOARD, '/setting'),
    role: path(ROOTS_DASHBOARD, '/setting/role'),
    roleadd: path(ROOTS_DASHBOARD, '/setting/role/add'),

    post: path(ROOTS_DASHBOARD, '/post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
