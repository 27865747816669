export default {
  START_FETCH_NOTI_CAMPAIGN_LIST: 'START_FETCH_NOTI_CAMPAIGN_LIST',
  FETCH_NOTI_CAMPAIGN_LIST_FAILURE: 'FETCH_NOTI_CAMPAIGN_LIST_FAILURE',
  FETCH_NOTI_CAMPAIGN_LIST_SUCCESS: 'FETCH_NOTI_CAMPAIGN_LIST_SUCCESS',

  START_CREATE_NOTI_CAMPAIGN: 'START_CREATE_NOTI_CAMPAIGN',
  CREATE_NOTI_CAMPAIGN_FAILURE: 'CREATE_NOTI_CAMPAIGN_FAILURE',
  CREATE_NOTI_CAMPAIGN_SUCCESS: 'CREATE_NOTI_CAMPAIGN_SUCCESS',

  START_FETCH_NOTI_CAMPAIGN_ONE: 'START_FETCH_NOTI_CAMPAIGN_ONE',
  FETCH_NOTI_CAMPAIGN_ONE_FAILURE: 'FETCH_NOTI_CAMPAIGN_ONE_FAILURE',
  FETCH_NOTI_CAMPAIGN_ONE_SUCCESS: 'FETCH_NOTI_CAMPAIGN_ONE_SUCCESS',

  START_UPDATE_NOTI_CAMPAIGN: 'START_UPDATE_NOTI_CAMPAIGN',
  UPDATE_NOTI_CAMPAIGN_FAILURE: 'UPDATE_NOTI_CAMPAIGN_FAILURE',
  UPDATE_NOTI_CAMPAIGN_SUCCESS: 'UPDATE_NOTI_CAMPAIGN_SUCCESS',

  START_FETCH_NOTI_LABELS: 'START_FETCH_NOTI_LABELS',
  FETCH_NOTI_LABELS_FAILURE: 'FETCH_NOTI_LABELS_FAILURE',
  FETCH_NOTI_LABELS_SUCCESS: 'FETCH_NOTI_LABELS_SUCCESS'
};
