import userType from '../action/action.type/user.type';
import usertype from '../action/action.type/user.type';
const initialState = {
  isLoading: false,
  error: false,
  data: [],
  success: null,
  message: '',
  paging: null,
  dataadd: [],
  successadd: null,
  messageadd: null,
  dataone: [],
  successone: null,
  dataupdate: [],
  successupdate: null,
  lastpage: 1,
  dataterm: [],
  successterm: null,
  messageterm: null,
  successlogout: null,
  messagelogout: null,
  histories: null,
  errorhistory: false,
  successhistory: null,
  messagehistory: null,
  successupgradepro: null,
  errorupgradepro: false,
  messageupgradepro: null,
  successDowngradePro: null,
  errorDowngradePro: false,
  messageDowngradePro: null,
  usersDomain: [],
  totalPageUsersDomain: [],
  businessByUser: [],
  domains: [],
  packages: null,
  userSubscription: null,
  userDroppii: [],
  historyByUser: [],
  metaUserDroppii: {
    totalPages: 1,
    totalUsers: 0,
    totalExpired: 0,
    totalUnexpired: 0
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case usertype.START_FETCH_USER:
      return { ...state, isLoading: true, data: [], error: false, success: null, message: null };
    case usertype.START_LOGOUT:
      return { ...state, isLoading: true, error: false, successlogout: null, messagelogout: null };
    case usertype.START_FETCH_TERM:
      return { ...state, isLoading: true, dataterm: [], error: false, successterm: null, messageterm: null };
    case usertype.START_FETCH_USER_ONE:
      return { ...state, isLoading: true, dataone: [], error: false, successone: null, message: null };
    case usertype.START_UPDATE_USER:
      return { ...state, isLoading: true, dataupdate: [], error: false, successupdate: null, message: null };
    case usertype.START_ADD_USER:
      return { ...state, isLoading: true, dataadd: [], error: false, successadd: null, messageadd: null };
    case usertype.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.data,
        success: action.success,
        message: action.message,
        lastpage: action.lastpage
      };
    case usertype.FETCH_TERM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataterm: action.data,
        successterm: action.success,
        messageterm: action.message
        // lastpage: action.lastpage
      };
    case usertype.FETCH_USER_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataone: action.data,
        successone: action.success,
        message: action.message
      };
    case usertype.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataupdate: action.data,
        successupdate: action.success,
        message: action.message
      };
    case usertype.ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataadd: action.data,
        successadd: action.success,
        messageadd: action.message
      };
    case usertype.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        successlogout: action.success,
        messagelogout: action.message
      };
    case usertype.FETCH_USER_FAILURE:
      return { ...state, isLoading: false, error: true, data: [], message: action.message };
    case usertype.FETCH_TERM_FAILURE:
      return { ...state, isLoading: false, error: true, dataterm: [], messageterm: action.message, successterm: false };
    case usertype.UPDATE_USER_FAILURE:
      return { ...state, isLoading: false, error: true, dataupdate: [], message: action.message, successupdate: false };
    case usertype.ADD_USER_FAILURE:
      return { ...state, isLoading: false, error: true, dataadd: [], messageadd: action.message, successadd: false };
    // Fetch user history
    case userType.START_FETCH_USER_HISTORY:
      return { ...state, isLoading: true, errorhistory: false, histories: null };
    case userType.FETCH_USER_HISTORY_SUCCESS:
      return { ...state, isLoading: false, histories: action.data, errorhistory: false, successhistory: true };
    case userType.FETCH_USER_HISTORY_FAILURE:
      return { ...state, isLoading: false, histories: null, errorhistory: true, successhistory: false };
    // Upgrade pro
    case userType.START_UPGRADE_PRO:
      return { ...state, isLoading: true, errorupgradepro: false, successupgradepro: null };
    case userType.UPGRADE_PRO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorupgradepro: false,
        successupgradepro: true,
        messageupgradepro: action.message
      };
    case userType.UPGRADE_PRO_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorupgradepro: true,
        successupgradepro: false,
        messageupgradepro: action.message
      };
    // downgrade pro
    case userType.START_DOWNGRADE_PRO:
      return { ...state, isLoading: true, errorupgradepro: false, successupgradepro: null };
    case userType.DOWNGRADE_PRO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorDowngradePro: false,
        successDowngradePro: true,
        messageDowngradePro: action.message
      };
    case userType.DOWNGRADE_PRO_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorDowngradePro: true,
        successDowngradePro: false,
        messageDowngradePro: action.message
      };
    // get list user domain
    case userType.START_FETCH_USER_DOMAIN:
      return { ...state, isLoading: true, usersDomain: [], error: false, success: null, message: null };
    case userType.FETCH_USER_DOMAIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        usersDomain: action.data,
        success: action.success,
        message: action.message,
        totalPageUsersDomain: action.totalPage
      };
    case userType.FETCH_USER_DOMAIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        usersDomain: [],
        totalPageUsersDomain: 1,
        message: action.message
      };
    // get domains
    case userType.START_FETCH_DOMAINS:
      return { ...state, domains: [] };
    case userType.FETCH_DOMAINS_SUCCESS:
      return { ...state, domains: action.data };
    case userType.FETCH_DOMAINS_FAILURE:
      return { ...state, domains: [] };
    // get list business by user
    case userType.START_FETCH_BUSINESS_BY_USER:
      return { ...state, businessByUser: [], error: false, success: null, message: null };
    case usertype.RESET_BUSINESS_BY_USER:
      return { ...state, businessByUser: [] };
    case userType.FETCH_BUSINESS_BY_USER_SUCCESS:
      return {
        ...state,
        error: false,
        businessByUser: action.data,
        success: action.success,
        message: action.message
      };
    case userType.FETCH_BUSINESS_BY_USER_FAILURE:
      return {
        ...state,
        error: true,
        businessByUser: [],
        message: action.message
      };
    case userType.RESET_STATUS_DOWNGRADE_PRO:
      return {
        ...state,
        isLoading: false,
        errorDowngradePro: false,
        successDowngradePro: null,
        messageDowngradePro: null
      };
    // get packages
    case userType.START_FETCH_PACKAGES:
      return { ...state, packages: null };
    case userType.FETCH_PACKAGES_SUCCESS:
      return { ...state, packages: action.data };
    case userType.FETCH_PACKAGES_FAILURE:
      return { ...state, packages: null };
    // get user subscription
    case userType.START_FETCH_USER_SUBSCRIPTION:
      return { ...state, userSubscription: null };
    case userType.FETCH_USER_SUBSCRIPTION_SUCCESS:
      return { ...state, userSubscription: action.data };
    case userType.FETCH_USER_SUBSCRIPTION_FAILURE:
      return { ...state, userSubscription: null };
    case userType.START_FETCH_USERS_DROPPII:
      return { ...state, isLoading: true, userDroppii: [], error: false, success: null };
    case userType.FETCH_USERS_DROPPII_SUCCESS:
      return {
        ...state,
        userDroppii: action.data,
        isLoading: false,
        error: false,
        success: true,
        metaUserDroppii: action.meta
      };
    case userType.FETCH_USERS_DROPPII_FAILURE:
      return {
        ...state,
        userDroppii: action.data,
        isLoading: false,
        success: null,
        error: true,
        totalUserDroppii: 0,
        metaUserDroppii: {
          totalPages: 1,
          totalUsers: 0,
          totalExpired: 0,
          totalUnexpired: 0
        }
      };
    case usertype.RESET_USERS:
      return {
        ...state,
        data: []
      };
    case usertype.RESET_USER_DETAIL:
      return {
        ...state,
        dataone: []
      };
    //history by user
    case usertype.START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER:
      return { ...state, historyByUser: [] };
    case usertype.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS:
      return { ...state, historyByUser: action.data };
    case usertype.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE:
      return { ...state, historyByUser: [] };
    default:
      return state;
  }
};

export default userReducer;
