//subscription plan
export const PACKAGE_KEY = {
  free: 'sbh_package_free',
  plus: 'sbh_package_plus',
  plus_advance: 'sbh_package_plus_advanced',
  pro: 'sbh_package_pro',
  create_business: 'custom_package_create_business',
  addon_report: 'custom_package_addon_report'
};

export const EMPTY_DATE = '01/01/0001';
export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
export const USER_INFO = 'userInfo';
export const PLATFORM_KEY = 'desktop-web';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const SAMPLE_UPLOAD_PACKAGE_DROPPII =
  'https://d3hr4eej8cfgwy.cloudfront.net/finan-prd/20962f85-88f9-49d9-938b-964e83ba11c3/data/b3a283be-1651-4e1c-9c40-31e9e889683803_04_2023_12_38_31.xlsx';

export const DEEPLINKS_APP = [
  { name: 'Drawer Navigation', value: 'DrawerNavigation' },
  { name: 'Main Stack', value: 'MainStack' },
  { name: 'Auth Stack', value: 'AuthStack' },
  { name: 'Main Tab', value: 'MainTab' },
  { name: 'Store Stack', value: 'StoreStack' },
  { name: 'DebtBook Stack', value: 'DebtBookStack' },
  { name: 'Profile Stack', value: 'ProfileStack' },
  { name: 'Order Detail Screen', value: 'order_details' },
  { name: 'Order Screen Refunded Tab', value: 'order_refunded' },
  { name: 'Order Screen Canceled Tab', value: 'order_canceled' },
  { name: 'Order Screen Delivered Tab', value: 'order_delivered' },
  { name: 'Order Screen Delivering Tab', value: 'order_delivering' },
  { name: 'Order Screen Pending Tab', value: 'order_pending' },
  { name: 'Order Screen All Tab', value: 'order_all' },
  { name: 'Promotion Detail Screen', value: 'promo_details' },
  { name: 'Create Promotion Screen', value: 'promo_creation' },
  { name: 'Promotion Screen', value: 'promo_mgt' },
  { name: 'Inventory Detail Screen', value: 'inventory_details' },
  { name: 'Variant Detail Screen', value: 'VariantDetailScreen' },
  { name: 'Create Product Screen', value: 'product_creation' },
  { name: 'Store Home Screen', value: 'pos_home' },
  { name: 'Product Screen Category Tab', value: 'category_mgt' },
  { name: 'Product Screen Product Tab', value: 'product_mgt' },
  { name: 'Create Online Store Screen', value: 'CreateOnlineStoreScreen' },
  { name: 'Init Store Screen Continue', value: 'create_onlinestrore_2' },
  { name: 'Init Store Screen', value: 'create_onlinestrore_1' },
  { name: 'Report PDF Screen', value: 'ReportPDFScreen' },
  { name: 'Link Store Screen', value: 'shopee_integration_screen' },
  { name: 'Create Store Success', value: 'CreateStoreSuccess' },
  { name: 'WebView Screen', value: 'WebViewScreen' },
  { name: 'Contact List Screen', value: 'ContactListScreen' },
  { name: 'Test Screen', value: 'TestScreen' },
  { name: 'Animation White Screen', value: 'AnimationWhiteScreen' },
  { name: 'Analyze Transaction Detail Screen', value: 'AnalyzeTransactionDetailScreen' },
  { name: 'FAQ Detail Screen', value: 'FAQDetailScreen' },
  { name: 'FAQ Screen', value: 'FAQScreen' },
  { name: 'User Business Screen', value: 'UserBusinessScreen' },
  { name: 'Edit Infomation Screen', value: 'personal_info_screen' },
  { name: 'About Us Screen', value: 'AboutUsScreen' },
  { name: 'Change Language Screen', value: 'ChangeLanguageScreen' },
  { name: 'Create Debt Remind Screen', value: 'CreateDebtRemindScreen' },
  { name: 'Debt Remind Screen', value: 'DebtRemindScreen' },
  { name: 'Create Transaction Screen CreateOut', value: 'out_transaction_creation' },
  { name: 'Create Transaction Screen CreateIn', value: 'in_transaction_creation' },
  { name: 'Create Transaction Screen', value: 'CreateTransactionScreen' },
  { name: 'Edit Customer Screen', value: 'customer_profile_editing' },
  { name: 'Customer Detail Screen', value: 'customer_debt' },
  { name: 'Create Customer Screen', value: 'CreateCustomerScreen' },
  { name: 'Paybook History Screen', value: 'PaybookHistoryScreen' },
  { name: 'Search Screen Transaction', value: 'transaction_search' },
  { name: 'Search Screen Customer', value: 'customer_search' },
  { name: 'Terms Of Service Screen', value: 'TermsOfServiceScreen' },
  { name: 'Complete Info Screen', value: 'CompleteInfoScreen' },
  { name: 'Feature Set Screen', value: 'feature_set' },
  { name: 'Verify OTP Screen', value: 'otp_verification' },
  { name: 'CashBook Home Screen', value: 'cash_book' },
  { name: 'Profile Screen', value: 'ProfileScreen' },
  { name: 'DebtBook Home Screen', value: 'debt_book' },
  { name: 'Login Screen', value: 'login_screen' },
  { name: 'Splash Screen', value: 'splash_screen' },
  { name: 'CashBook Stack', value: 'CashBookStack' },
  { name: 'Chat Detail Screen', value: 'conversation' },
  { name: 'Chat Home Screen', value: 'chat' },
  { name: 'Smart Contact Home Screen', value: 'customer_mgt' },
  { name: 'Smart Contact Stack', value: 'SmartContactStack' },
  { name: 'Create Order', value: 'pos_order' },
  { name: 'Offline Order', value: 'pos_product' },
  { name: 'Remove Product From Cat Screen', value: 'RemoveProductFromCatScreen' },
  { name: 'Add Product To Category Screen', value: 'AddProductToCategoryScreen' },
  { name: 'Category Detail Screen', value: 'category_details' },
  { name: 'WebView Page', value: 'WebViewPage' },
  { name: 'Bill Screen', value: 'invoice_details' },
  { name: 'Create Multi Product Screen', value: 'CreateMultiProductScreen' },
  { name: 'Choose Business Type Screen', value: 'ChooseBusinessTypeScreen' },
  { name: 'Edit Order Screen', value: 'order_editing' },
  { name: 'OnBoarding Screen', value: 'onboarding_screen' },
  { name: 'Business Card Screen', value: 'business_card' },
  { name: 'Setting Screen', value: 'app_setting' },
  { name: 'Location Detail Screen', value: 'LocationDetailScreen' },
  { name: 'Edit Custom Domain Screen', value: 'EditCustomDomainScreen' },
  { name: 'Smart Contact Detail Screen', value: 'customer_profile' },
  { name: 'Create Contact Transaction Screen Update', value: 'debt_details' },
  { name: 'Create Contact Transaction Screen Create', value: 'debt_creation' },
  { name: 'Create Feed Screen', value: 'create_post' },
  { name: 'Feed Home Screen', value: 'post_mgt' },
  { name: 'WebView Store', value: 'WebViewStore' },
  { name: 'Chat Detail Screen Support', value: 'conversation_support' },
  { name: 'Chat Detail Screen Assistant', value: 'assistant_screen' },
  { name: 'Chat Noti Screen', value: 'ChatNotiScreen' },
  { name: 'Purchase Order Detail Screen Out', value: 'outbound_details' },
  { name: 'Purchase Order Detail Screen In', value: 'inbound_details' },
  { name: 'Inventory Camera', value: 'inventory_camera' },
  { name: 'Inventory History Screen', value: 'InventoryHistoryScreen' },
  { name: 'Sku Inventory Detail Screen', value: 'SkuInventoryDetailScreen' },
  { name: 'Inventory Home Screen', value: 'inventory' },
  { name: 'List Inventory Screen', value: 'ListInventoryScreen' },
  { name: 'Create Inventory Screen', value: 'CreateInventoryScreen' },
  { name: 'Setting Printer Screen', value: 'printer_setting' },
  { name: 'Inventory Stack', value: 'InventoryStack' },
  { name: 'Analytics Detail Category Screen', value: 'AnalyticsDetailCategoryScreen' },
  { name: 'Onboarding Survey Screen 3', value: 'onboarding_survey_3' },
  { name: 'Onboarding Survey Screen 2', value: 'onboarding_survey_2' },
  { name: 'Onboarding Survey Screen 1', value: 'onboarding_survey_1' },
  { name: 'Onboarding Survey Screen', value: 'onboarding_survey' },
  { name: 'Scan Product POS Screen', value: 'pos_scan' },
  { name: 'Group Chat Info Screen', value: 'GroupChatInfoScreen' },
  { name: 'Create Group Chat Screen', value: 'CreateGroupChatScreen' },
  { name: 'Order Detail Buyer View Screen', value: 'OrderDetailBuyerViewScreen' },
  { name: 'Search Purchase Order Screen', value: 'SearchPurchaseOrderScreen' },
  { name: 'Purchase Order Management Screen', value: 'inventory_book' },
  { name: 'Add Product Screen', value: 'sku_search' },
  { name: 'Create Purchase Order Screen Out', value: 'outbound_creation' },
  { name: 'Create Purchase Order Screen In', value: 'inbound_creation' },
  { name: 'Online Store Home Screen', value: 'online_store' },
  { name: 'My Reward Detail Screen', value: 'myreward_detail' },
  { name: 'Point History Screen', value: 'point_history' },
  { name: 'List Reward Screen', value: 'reward_search' },
  { name: 'Loyalty Mission Screen', value: 'loyalty_mission' },
  { name: 'Reward Detail Screen', value: 'reward_details' },
  { name: 'My Reward Screen', value: 'my_reward' },
  { name: 'Loyalty Home Screen', value: 'reward_store' },
  { name: 'Onboarding Survey Screen 4', value: 'onboarding_survey_4' },
  { name: 'Inventory Report Screen Overview', value: 'inventory_dashboard' },
  { name: 'Store Analytics Screen Analytics', value: 'store_dashboard_report' },
  { name: 'Store Analytics Screen Today', value: 'store_dashboard_realtime' },
  { name: 'Profit Filter By Category Screen', value: 'ProfitFilterByCategoryScreen' },
  { name: 'Profit Loss Report Screen', value: 'pl_report' },
  { name: 'HDBank Screen', value: 'hdbank_account' },
  { name: 'Add Bank Account Screen', value: 'select_bank_accounts' },
  { name: 'Onboarding Pro Screen', value: 'landingpage_proversion' },
  { name: 'Product List Ecom Screen', value: 'shopee_product_screen' },
  { name: 'Payment Method Screen', value: 'payment_method' },
  { name: 'Store Info Screen', value: 'store_infomation' },
  { name: 'Fee Ship Screen', value: 'ship_cost' },
  { name: 'Web Sell Screen', value: 'store_website' },
  { name: 'Store Setting Screen', value: 'store_setting' },
  { name: 'Application Screen', value: 'applications' },
  { name: 'Input Referral Screen', value: 'referral_program_reciever' },
  { name: 'Referral List Screen', value: 'referral_program_reciever_list' },
  { name: 'Referral Home Screen', value: 'referral_program_sender' },
  { name: 'CashBook Analytics Screen', value: 'cash_book_dashboard' },
  { name: 'Inventory Report Screen Analytics', value: 'inventory_dashboard_report' },
  { name: 'Reservations Screen', value: 'reservation' },
  { name: 'General Setting Order', value: 'pos_setting' },
  { name: 'General Setting Product', value: 'product_setting' },
  { name: 'Accumulate Customer Point Screen', value: 'customer_point' },
  { name: 'Table Management Tab', value: 'table_mgt_tab_table' },
  { name: 'Table Management Create Tab', value: 'table_mgt_tab_create' },
  { name: 'Featured Category Detail Screen', value: 'FeaturedCategoryDetailScreen' },
  { name: 'Create Featured Category Screen', value: 'CreateFeaturedCategoryScreen' },
  { name: 'Featured Category List Screen', value: 'special_block' },
  { name: 'Display Setting Screen', value: 'buyerview_setting' },
  { name: 'Confirm Payment Screen New', value: 'confirm_payment' },
  { name: 'Confirm Payment Screen', value: 'confirm_payment' },
  { name: 'Customer List', value: 'CustomerList' },
  { name: 'Invoice Adjustment Screen', value: 'invoice_adjustment' },
  { name: 'Role Management Screen', value: 'RoleManagementScreen' },
  { name: 'Create Staff Screen', value: 'CreateStaffScreen' },
  { name: 'Staff Management Screen', value: 'staff_mgt' },
  { name: 'Data Backup Screen', value: 'backup_data' },
  { name: 'List Payment Method HBBank Screen', value: 'ListPaymentMethodHBBankScreen' },
  { name: 'Scan Result Screen', value: 'ScanResultScreen' },
  { name: 'QR Camera Screen', value: 'QRCameraScreen' },
  { name: 'Update TabBar Item Screen', value: 'UpdateTabBarItemScreen' },
  { name: 'Payment Webview Screen', value: 'PaymentWebviewScreen' },
  { name: 'Payment Detail Screen', value: 'payment_result' },
  { name: 'Transfers Screen', value: 'TransfersScreen' },
  { name: 'Payment Source Screen', value: 'PaymentSourceScreen' },
  { name: 'Report Analytics Screen', value: 'ReportAnalyticsScreen' },
  { name: 'Table Management Screen Edit', value: 'table_setting' },
  { name: 'Table Management Screen', value: 'table_mgt' },
  { name: 'Link WebSite Screen', value: 'online_store_setting' },
  { name: 'Address Shipping Info Screen', value: 'AddressShippingInfoScreen' },
  { name: 'List Address Shipping Screen', value: 'ListAddressShippingScreen' },
  { name: 'Shipping Management Screen', value: 'ShippingManagementScreen' },
  { name: 'Shipping Connection Screen', value: 'delivery_mgt' },
  { name: 'QR Payment Screen', value: 'pos_qr' },
  { name: 'Product List Addon Link Screen', value: 'ProductListAddonLinkScreen' },
  { name: 'Create Addon Product Screen', value: 'CreateAddonProductScreen' },
  { name: 'Neox WebView Screen', value: 'NeoxWebViewScreen' },
  { name: 'Gate Payment Screen', value: 'GatePaymentScreen' },
  { name: 'Request Order Detail View Buyer Screen', value: 'RequestOrderDetailViewBuyerScreen' },
  { name: 'Request Order Detail View Seller Screen', value: 'RequestOrderDetailViewSellerScreen' },
  { name: 'Deliver Info Detail Screen', value: 'DeliverInfoDetailScreen' },
  { name: 'Enter Deliver Info Screen', value: 'EnterDeliverInfoScreen' },
  { name: 'Enter Contact Address Screen', value: 'EnterContactAddressScreen' },
  { name: 'Global Search Screen', value: 'global_search' },
  { name: 'Fortune Screen', value: 'xin_via' },
  { name: 'CUV Role Screen', value: 'CUVRoleScreen' },
  { name: 'Profile Photo Screen', value: 'avatar' },
  { name: 'Add Store Screen', value: 'extra_store_upgrade' },
  { name: 'Renew Store Screen', value: 'extra_store_renew' },
  { name: 'Onboarding Connect Facebook Screen', value: 'connect_facebook' },
  { name: 'Manage Page Facebook Screen', value: 'connect_facebook_success' },
  { name: 'Link Facebook Page Screen', value: 'LinkFacebookPageScreen' },
  { name: 'Auto Messages Screen', value: 'chat_auto' },
  { name: 'Absent Message Screen', value: 'chat_off' },
  { name: 'Unsync Order Screen', value: 'UnsyncOrderScreen' },
  { name: 'Import Product Management Screen', value: 'ImportProductManagementScreen' },
  { name: 'Quick CashScreen Home', value: 'collect_payment' },
  { name: 'Quick CashScreen Onboard 1', value: 'onboarding_qr_1' },
  { name: 'Quick CashScreen Onboard 2', value: 'onboarding_qr_2' },
  { name: 'Quick CashScreen Onboard 3', value: 'onboarding_qr_3' },
  { name: 'Quick CashScreen Empty', value: 'collect_payment_empty_state' },
  { name: 'Connect Bank Screen', value: 'account_info' },
  { name: 'Collection Information Screen', value: 'qr_payment' },
  { name: 'Payment Results Screen', value: 'qr_payment_result' },
  { name: 'Return Order Detail Screen', value: 'order_return_details' },
  { name: 'Import Product Detail Screen', value: 'purchase_order_details' },
  { name: 'Save Customer Info Screen', value: 'SaveCustomerInfoScreen' },
  { name: 'Create Ingredient Screen', value: 'ingredient_creation' },
  { name: 'Materials Management Screen', value: 'ingredient_mgt' },
  { name: 'Shift Management Screen', value: 'shift_mgt' },
  { name: 'Shift Detail Screen', value: 'shift_detail' },
  { name: 'Permission Open Shift Screen', value: 'PermissionOpenShiftScreen' },
  { name: 'Onboarding Mission Screen', value: 'onboarding_mission' },
  { name: 'Connect EInvoice Screen', value: 'invoice_connection' },
  { name: 'Login EInvoice Screen', value: 'LoginEInvoiceScreen' },
  { name: 'Information Export EInvoice Screen', value: 'InformationExportEInvoiceScreen' },
  { name: 'EInvoice Detail Screen', value: 'EInvoiceDetailScreen' },
  { name: 'EInvoice Management Screen', value: 'invoice_release' },
  { name: 'Enter Contact Address V2 Screen', value: 'EnterContactAddressV2Screen' },
  { name: 'Select Address Screen', value: 'SelectAddressScreen' }
];

export const TRAINER_COMMISSION_WHITELIST = ['0902035198', '0908367111', '0931088939', '0932118873', '0774926989'];
export const numberFormat = new Intl.NumberFormat('vi-VN', { maximumFractionDigits: 4 });
