import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 62;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 18,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isPOS = location.pathname.includes(PATH_DASHBOARD.finan.pos);

  return (
    <RootStyle>
      <DashboardNavbar isPOS={isPOS} onOpenSidebar={() => setOpen(true)} />
      {!isPOS && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      <MainStyle sx={{ paddingBottom: isPOS && 4, paddingTop: isPOS && '100px !important' }}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
