import React from 'react'; // routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getOtherIcon = (name) => (
  <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  noti: getIcon('ic_notification_package'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  // noti: getIcon('chat'),
  shopvetified: getIcon('market'),
  shopmanager: getIcon('entrepreneur'),
  banner: getIcon('ads'),
  order: getIcon('shopping-bag'),
  link: getIcon('link'),
  browser: getOtherIcon('browser-edge'),
  challenge: getIcon('ads'),
  elephant: getOtherIcon('elephant'),
  chrome: getOtherIcon('ic_chrome'),
  code: getOtherIcon('ic_code'),
  design: getOtherIcon('ic_design'),
  drive: getOtherIcon('ic_drive'),
  dropbox: getOtherIcon('ic_dropbox'),
  evernote: getOtherIcon('ic_evernote'),
  camera: getOtherIcon('love-camera'),
  shapeAvatar: getOtherIcon('shape-avatar'),
  shield: getOtherIcon('shield'),
  connection: getIcon('connection')
};

const sidebarConfig = [
  {
    subheader: 'FINAN',
    items: [
      { title: 'POS', path: PATH_DASHBOARD.finan.pos, icon: ICONS.cart },
      // { title: 'SUBSCRIPTION', path: PATH_DASHBOARD.finan.subscription, icon: ICONS.shield },
      { title: 'POS ORDER', path: PATH_DASHBOARD.finan.order, icon: ICONS.order },
      // {
      //   title: 'NOTI MANAGEMENT',
      //   path: PATH_DASHBOARD.finan.notiManagement,
      //   icon: ICONS.noti,
      //   role: 'admin',
      //   children: [
      //     { title: 'NOTI', path: PATH_DASHBOARD.finan.noti },
      //     { title: 'NOTI CAMPAIGN', path: PATH_DASHBOARD.finan.notiCampaign }
      //   ]
      // },
      {
        title: 'USER MANAGER',
        path: PATH_DASHBOARD.finan.userManager,
        icon: ICONS.user,
        role: 'admin',
        children: [{ title: 'USER', path: PATH_DASHBOARD.finan.user }]
      }
      // {
      //   title: 'OTHER',
      //   path: PATH_DASHBOARD.finan.setting,
      //   icon: ICONS.dropbox,
      //   children: [{ title: 'ROLE', path: PATH_DASHBOARD.finan.role, icon: ICONS.user }]
      // }
      // {
      //   title: 'METADATA',
      //   path: PATH_DASHBOARD.finan.metadata,
      //   icon: ICONS.analytics,
      //   children: [
      //     { title: 'TAXONOMIES', path: PATH_DASHBOARD.finan.taxonomies },
      //     { title: 'TERM', path: PATH_DASHBOARD.finan.term },
      //     { title: 'TERM - TAXONOMIES', path: PATH_DASHBOARD.finan.termTaxonomy },
      //     { title: 'TERM - RELATIONSHIPS', path: PATH_DASHBOARD.finan.termRelationship },
      //     { title: 'TERM - TAXONOMY DETAILS', path: PATH_DASHBOARD.finan.termTaxonomyDetail }
      //   ],
      //   role: 'admin'
      // }
    ]
  }

  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account }
  //       ]
  //     },
  //   ]
  // },
];

export default sidebarConfig;
