import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import notiReducer from './reducer/noti.reducer';
import notiCampaignReducer from './reducer/notiCampaign.reducer';
import usermanagerReducer from './reducer/user.reducer';
import bannerReducer from './reducer/banner.reducer';
import roleReducer from './reducer/role.reducer';
import packageReducer from './reducer/package.reducer';
import campaignReducer from './reducer/campaign.reducer';
import znsCampaignReducer from './reducer/znsCampaign.reducer';
import posReducer from './reducer/pos.reducer';
import businessReducer from './reducer/business.reducer';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  notiReducer: notiReducer,
  notiCampaignReducer: notiCampaignReducer,
  userReducer: usermanagerReducer,
  packageReducer: packageReducer,
  bannerReducer: bannerReducer,
  roleReducer: roleReducer,
  product: persistReducer(productPersistConfig, productReducer),
  campaignReducer: campaignReducer,
  znsCampaignReducer: znsCampaignReducer,
  posReducer: posReducer,
  businessReducer: businessReducer
});

export { rootPersistConfig, rootReducer };
