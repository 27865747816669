
import bannerType from '../action/action.type/banner.types';
const initialState = {
    isLoading: false,
    dataupdate: [], error: false, successupdate: null, messageupdate: null,
    data: [],
    success: null,
    message: '',
    paging: null,
    dataadd: [],
    successadd: null,
    messageadd: null,
    lastpage: 1,
    dataone: {},
    databanner: [], successbanner: null, messagebanner: null,
    datatype: [], successtype: null, messagetype: null,
};
const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case bannerType.START_FETCH_BANNER:
            return { ...state, isLoading: true, data: [], error: false, success: null, message: null }
        case bannerType.START_UPDATE_BANNER:
            return { ...state, isLoading: true, dataupdate: [], error: false, successupdate: null, message: null }
        case bannerType.START_FETCH_BANNER_TYPE:
            return { ...state, isLoading: true, datatype: [], error: false, successtype: null, messagetype: null }
        case bannerType.START_FETCH_BANNER_ONE:
            return { ...state, isLoading: true, dataone: [], error: false, successone: null, messageone: null }
        case bannerType.START_CREATE_BANNER:
            return { ...state, isLoading: true, databanner: [], error: false, successbanner: null, messagebanner: null }
        case bannerType.FETCH_BANNER_SUCCESS:
            return { ...state, isLoading: false, data: action.data, error: false, success: action.success, messages: action.messages, lastpage: action.lastpage }
        case bannerType.CREATE_BANNER_SUCCESS:
            return { ...state, isLoading: false, databanner: action.data, error: false, successbanner: action.success, messagebanner: action.messages }
        case bannerType.FETCH_BANNER_ONE_SUCCESS:
            return { ...state, isLoading: false, dataone: action.data, error: false, successone: action.success, messageone: action.messages, }
        case bannerType.UPDATE_BANNER_SUCCESS:
            return { ...state, isLoading: false, dataupdate: action.data, error: false, successupdate: action.success, message: action.messages, }
        case bannerType.FETCH_BANNER_TYPE_SUCCESS:
            return { ...state, isLoading: false, datatype: action.data, error: false, successtype: action.success, messagetype: action.messages, }

        case bannerType.FETCH_BANNER_TYPE_FAILURE:
            return { ...state, isLoading: false, datatype: [], error: true, successtype: false, messagetype: null }
        case bannerType.UPDATE_BANNER_FAILURE:
            return { ...state, isLoading: false, dataupdate: [], error: true, successupdate: false, message: null }
        case bannerType.CREATE_BANNER_FAILURE:
            return { ...state, isLoading: false, databanner: [], error: true, successbanner: false, messagebanner: null }
        case bannerType.FETCH_BANNER_ONE_FAILURE:
            return { ...state, isLoading: false, dataone: [], error: true, successone: false, messageone: null }
        default:
            return state;
    }
};

export default bannerReducer;
