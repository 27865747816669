import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to={PATH_DASHBOARD.finan.user} replace /> },
        {
          path: 'pos',
          children: [{ path: '', element: <POS /> }]
        },
        {
          path: 'subscription',
          children: [{ path: '', element: <Subscription /> }]
        },
        {
          path: 'business',
          children: [{ path: ':id', element: <Business /> }]
        },
        {
          path: 'order',
          children: [{ path: '', element: <Order /> }]
        },
        {
          path: 'noti-management',
          children: [
            { path: 'noti', element: <NotiList /> },
            { path: 'noti/add', element: <AddNoti /> },
            { path: 'noti/:id/edit', element: <EditNoti /> },
            { path: 'campaign', element: <NotiCampaignList /> },
            { path: 'campaign/add', element: <AddNotiCampaign /> },
            { path: 'campaign/:id/edit', element: <EditNotiCampaign /> }
          ]
        },
        {
          path: 'usermanager',
          children: [
            { path: 'user', element: <UserManager /> },
            { path: ':id/edit', element: <EditUserManager /> },
            { path: ':id', element: <DetailUserManager /> },
            { path: 'business-profile/:id', element: <BusinessProfileManager /> }
          ]
        },
        {
          path: 'setting/role',
          children: [
            { path: '', element: <Role /> },
            { path: 'add', element: <RoleAdd /> },
            { path: ':id/edit', element: <RoleEdit /> }
          ]
        },
        // {
        //   path: 'banner',
        //   children: [
        //     { path: '', element: <BannerConfig /> },
        //     { path: 'add', element: <BannerAdd /> },
        //     { path: ':id/details', element: <BannerEdit /> }
        //     // { path: '/:id/edit', element: <EditUserManager /> },
        //   ]
        // },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Main Routes
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'coming-soon', element: <ComingSoon /> },
    //     { path: 'maintenance', element: <Maintenance /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> }
    //   ]
    // },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));

const POS = Loadable(lazy(() => import('../pages/finan/POS/index')));
const Subscription = Loadable(lazy(() => import('../pages/finan/Subscription/index')));
const Business = Loadable(lazy(() => import('../pages/finan/Business/index')));
const Order = Loadable(lazy(() => import('../pages/finan/Order/index')));

const UserManager = Loadable(lazy(() => import('../pages/finan/UserManager/index')));
const EditUserManager = Loadable(lazy(() => import('../pages/finan/UserManager/EditUser')));
const DetailUserManager = Loadable(lazy(() => import('../pages/finan/UserManager/DetailUser')));
const BusinessProfileManager = Loadable(lazy(() => import('../pages/finan/UserManager/BusinessProfile')));

const Role = Loadable(lazy(() => import('../pages/finan/Role/index')));
const RoleAdd = Loadable(lazy(() => import('../pages/finan/Role/addRole')));
const RoleEdit = Loadable(lazy(() => import('../pages/finan/Role/editRole')));

const BannerConfig = Loadable(lazy(() => import('../pages/finan/Banner/index')));
const BannerAdd = Loadable(lazy(() => import('../pages/finan/Banner/addBanner')));
const BannerEdit = Loadable(lazy(() => import('../pages/finan/Banner/editBanner')));

// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const NotiList = Loadable(lazy(() => import('../pages/finan/noti/index')));
const AddNoti = Loadable(lazy(() => import('../pages/finan/noti/AddNoti')));
const EditNoti = Loadable(lazy(() => import('../pages/finan/noti/EditNoti')));

const NotiCampaignList = Loadable(lazy(() => import('../pages/finan/notiCampaign/index')));
const AddNotiCampaign = Loadable(lazy(() => import('../pages/finan/notiCampaign/AddNoti')));
const EditNotiCampaign = Loadable(lazy(() => import('../pages/finan/notiCampaign/EditNoti')));
