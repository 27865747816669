import { getTimeZone } from '../../utils/DateUtils';
import { getLocaleCode } from '../../utils/commonUtils';
import { ACCESS_TOKEN } from '../../utils/constant';
import axiosClient from './axiosClient';
import codes from './errorCode';

export const getData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  try {
    return await axiosClient.get(url, { params, headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

async function fetchAllData(input, init) {
  const headers = {};

  if (init?.authorization) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) {
      throw new Error('UNAUTHORIZE');
    }
    headers.authorization = `Bearer ${token}`;
  }

  const rs = await fetch(input, {
    ...init,
    headers: {
      ...headers,
      ...init?.headers,
      'x-location-timezone': `UTC${getTimeZone()}`,
      'x-locale-code': getLocaleCode()
    }
  });

  if (rs.status >= 400) {
    const json = await rs.json();
    throw new Error(json?.message || json.error.detail || 'ERR_SYSTEM');
  }

  return rs;
}

export async function fetchStreamData(input, init) {
  return await fetchAllData(input, init);
}

export const getStreamData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  try {
    return await axiosClient.get(url, { params, headers, responseType: 'stream' });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const postData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  try {
    return await axiosClient.post(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const postUploadData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'multipart/form-data',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode()
  };
  // console.log(url);
  // console.log(params);
  // console.log(JSON.stringify(params))
  // console.log(headers);
  // let formData = new FormData()
  // for (const key in params) {
  //   if (params.hasOwnProperty(key)) {
  //     const val = params[key];
  //     formData.append(key, val);
  //   }
  // }
  try {
    return await axiosClient.post(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const putData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  console.log(url);
  console.log(JSON.stringify(params));
  console.log(headers);
  try {
    return await axiosClient.put(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const putUploadData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'multipart/form-data',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode()
  };
  console.log(url);
  // console.log(JSON.stringify(params))
  console.log(headers);
  try {
    return await axiosClient.post(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const patchData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  try {
    return await axiosClient.patch(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const patchUploadData = async (url, params, md5, headersParam, callbackError) => {
  let headers = {
    'Content-Type': 'multipart/form-data',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode()
  };
  console.log(url);
  // console.log(JSON.stringify(params))
  console.log(headers);
  try {
    return await axiosClient.patch(url, params, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export const deleteData = async (url, callbackError, headersParam = {}) => {
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'x-location-timezone': `UTC${getTimeZone()}`,
    'x-locale-code': getLocaleCode(),
    // Authorization: md5,
    ...headersParam
  };
  try {
    return await axiosClient.delete(url, { headers });
  } catch (error) {
    catchError(error, callbackError);
  }
};

export function catchError(error, callbackError) {
  // console.log(error?.response);
  // console.log(error?.response?.status);
  // console.log(codes[error?.response?.status]);
  if (callbackError) {
    // return callbackError(error)
    if (error.response.status === 408 || error.response.status === 403) {
      return callbackError({
        status: error.response.status,
        message: 'Vui lòng đăng nhập lại !'
      });
    } else {
      return callbackError({
        status: error.response.status,
        message: error?.response?.data?.error?.detail || error?.response?.data?.message
      });
    }
  }
  // return Alert.alert(
  //   'Error',
  //   `${error?.response.status}\n${codes[error?.response.status] || 'Lỗi kết nối'
  //   }`
  // )
  if (error.response.status === 408) {
    return callbackError({
      status: error.response.status,
      message: 'Vui lòng đăng nhập lại !',
      error: 'Phiên truy cập hết hạn'
    });
  }
  return callbackError({
    status: error?.response?.status || '',
    message: error?.response?.data || ''
  });
}

export const apiService = {
  getData,
  getStreamData,
  postData,
  putData,
  postUploadData,
  putUploadData,
  patchData,
  patchUploadData,
  deleteData
};
