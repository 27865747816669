import notiTypes from '../action/action.type/noti.types';
import notiType from '../action/action.type/noti.types';
const initialState = {
  isLoading: false,
  dataupdate: [],
  error: false,
  successupdate: null,
  messageupdate: null,
  data: [],
  success: null,
  message: '',
  paging: null,
  dataadd: [],
  successadd: null,
  messageadd: null,
  lastpage: 1,
  dataone: {},
  successone: null,
  datasend: [],
  successsend: null,
  messagesend: null,
  smartTagType: [],
  successSmartTagType: null,
  deeplinks: [],
  successDeeplinks: null
};

const notiReducer = (state = initialState, action) => {
  switch (action.type) {
    case notiType.START_FETCH_NOTI:
      return { ...state, isLoading: true, data: [], error: false, success: null, message: null };
    case notiType.START_SEND_NOTI:
      return { ...state, isLoading: true, datasend: [], error: false, successsend: null, messagesend: null };
    case notiType.START_FETCH_NOTI_ONE:
      return { ...state, isLoading: true, dataone: {}, error: false, successone: null, message: null };
    case notiType.START_ADD_NOTI:
      return { ...state, isLoading: true, dataadd: [], error: false, successadd: null, messageadd: null };
    case notiType.START_UPDATE_NOTI:
      return { ...state, isLoading: true, dataupdate: [], error: false, successupdate: null, messageupdate: null };
    case notiType.START_FETCH_SMART_TAG_TYPE:
      return { ...state, isLoading: true, smartTagType: [], error: false, successSmartTagType: null };
    case notiType.START_FETCH_DEEPLINKS:
      return { ...state, isLoading: true, deeplinks: [], error: false, successDeeplinks: null };
    case notiType.FETCH_NOTI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.data,
        success: action.success,
        message: action.message,
        lastpage: action.lastpage
      };
    case notiType.FETCH_NOTI_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataone: action.data,
        successone: action.success,
        message: action.message
        // lastpage: action.lastpage
      };
    case notiType.ADD_NOTI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataadd: action.data,
        successadd: action.success,
        messageadd: action.message
      };
    case notiType.SEND_NOTI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        datasend: action.data,
        successsend: action.success,
        messagesend: action.message
      };
    case notiType.UPDATE_NOTI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        dataupdate: action.data,
        successupdate: action.success,
        messageupdate: action.message
      };
    case notiType.FETCH_SMART_TAG_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        smartTagType: action.data,
        successSmartTagType: action.success
      };
    case notiType.FETCH_DEEPLINKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        deeplinks: action.data,
        successDeeplinks: action.success
      };
    case notiType.FETCH_NOTI_FAILURE:
      return { ...state, isLoading: false, error: true, data: [], message: action.message };
    case notiType.SEND_NOTI_FAILURE:
      return { ...state, isLoading: false, error: true, datasend: [], messagesend: action.message, successsend: false };
    case notiType.FETCH_NOTI_ONE_FAILURE:
      return { ...state, isLoading: false, successone: false, error: true, dataone: {}, message: action.message };
    case notiType.ADD_NOTI_FAILURE:
      return { ...state, isLoading: false, error: true, dataadd: [], messageadd: action.message, successadd: false };
    case notiType.UPDATE_NOTI_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        dataupdate: [],
        messageupdate: action.message,
        successupdate: false
      };
    case notiType.FETCH_SMART_TAG_TYPE_FAILURE:
      return { ...state, isLoading: false, error: true, smartTagType: [] };
    case notiType.FETCH_DEEPLINKS_FAILURE:
      return { ...state, isLoading: false, error: true, deeplinks: [] };
    default:
      return state;
  }
};

export default notiReducer;
