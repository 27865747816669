import businessType from '../action/action.type/business.types';

const initialState = {
  subscriptionsByBiz: [],
  history: [],
  historyByUser: [],
  subscriptionDetail: [],
  orderDetail: null,
  businessDetail: null,
  loadingOrderDetail: true
};
const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    //subscriptions
    case businessType.START_FETCH_SUBSCRIPTIONS_BY_BIZ:
      return { ...state, subscriptionsByBiz: [] };
    case businessType.FETCH_SUBSCRIPTIONS_BY_BIZ_SUCCESS:
      return { ...state, subscriptionsByBiz: action.data };
    case businessType.FETCH_SUBSCRIPTIONS_BY_BIZ_FAILURE:
      return { ...state, subscriptionsByBiz: [] };

    //history
    case businessType.START_FETCH_SUBSCRIPTIONS_HISTORY:
      return { ...state, history: [] };
    case businessType.FETCH_SUBSCRIPTIONS_HISTORY_SUCCESS:
      return { ...state, history: action.data };
    case businessType.FETCH_SUBSCRIPTIONS_HISTORY_FAILURE:
      return { ...state, history: [] };

    //history by user
    case businessType.START_FETCH_SUBSCRIPTIONS_HISTORY_BY_USER:
      return { ...state, historyByUser: [] };
    case businessType.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_SUCCESS:
      return { ...state, historyByUser: action.data };
    case businessType.FETCH_SUBSCRIPTIONS_HISTORY_BY_USER_FAILURE:
      return { ...state, historyByUser: [] };

    //subscription detail
    case businessType.START_FETCH_SUBSCRIPTION_DETAIL_HISTORY:
      return { ...state, subscriptionDetail: [] };
    case businessType.FETCH_SUBSCRIPTION_DETAIL_HISTORY_SUCCESS:
      return { ...state, subscriptionDetail: action.data };
    case businessType.FETCH_SUBSCRIPTION_DETAIL_HISTORY_FAILURE:
      return { ...state, subscriptionDetail: [] };
    case businessType.RESET_SUBSCRIPTION_DETAIL:
      return { ...state, subscriptionDetail: [] };

    //order detail
    case businessType.START_FETCH_ORDER_DETAIL:
      return { ...state, orderDetail: null, loadingOrderDetail: true };
    case businessType.FETCH_ORDER_DETAIL_SUCCESS:
      return { ...state, orderDetail: action.data, loadingOrderDetail: false };
    case businessType.RESET_ORDER_DETAIL:
    case businessType.FETCH_ORDER_DETAIL_FAILURE:
      return { ...state, orderDetail: null, loadingOrderDetail: false };

    //business detail
    case businessType.START_FETCH_BUSINESS_DETAIL:
      return { ...state, businessDetail: null };
    case businessType.FETCH_BUSINESS_DETAIL_SUCCESS:
      return { ...state, businessDetail: action.data };
    case businessType.FETCH_BUSINESS_DETAIL_FAILURE:
      return { ...state, businessDetail: null };
    default:
      return state;
  }
};

export default businessReducer;
