export default {
  CREATE_CAMPAIGN_START: 'CREATE_CAMPAIGN_START',
  CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',

  UPDATE_CAMPAIGN_START: 'UPDATE_CAMPAIGN_START',
  UPDATE_CAMPAIGN_FAILURE: 'UPDATE_CAMPAIGN_FAILURE',
  UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',

  GET_CAMPAIGN_DETAIL_START: 'GET_CAMPAIGN_DETAIL_START',
  GET_CAMPAIGN_DETAIL_FAILURE: 'GET_CAMPAIGN_DETAIL_FAILURE',
  GET_CAMPAIGN_DETAIL_SUCCESS: 'GET_CAMPAIGN_DETAIL_SUCCESS',

  GET_LIST_CAMPAIGN_START: 'GET_LIST_CAMPAIGN_START',
  GET_LIST_CAMPAIGN_FAILURE: 'GET_LIST_CAMPAIGN_FAILURE',
  GET_LIST_CAMPAIGN_SUCCESS: 'GET_LIST_CAMPAIGN_SUCCESS',

  REQUEST_APPROVE_CAMPAIGN_START: 'REQUEST_APPROVE_CAMPAIGN_START',
  REQUEST_APPROVE_CAMPAIGN_FAILURE: 'REQUEST_APPROVE_CAMPAIGN_FAILURE',
  REQUEST_APPROVE_CAMPAIGN_SUCCESS: 'REQUEST_APPROVE_CAMPAIGN_SUCCESS',

  GET_LIST_SHOP_START: 'GET_LIST_SHOP_START',
  GET_LIST_SHOP_FAILURE: 'GET_LIST_SHOP_FAILURE',
  GET_LIST_SHOP_SUCCESS: 'GET_LIST_SHOP_SUCCESS',

  COPY_CAMPAIGN_START: 'COPY_CAMPAIGN_START',
  COPY_CAMPAIGN_FAILURE: 'COPY_CAMPAIGN_FAILURE',
  COPY_CAMPAIGN_SUCCESS: 'COPY_CAMPAIGN_SUCCESS',

  APPROVE_CAMPAIGN_START: 'APPROVE_CAMPAIGN_START',
  APPROVE_CAMPAIGN_FAILURE: 'APPROVE_CAMPAIGN_FAILURE',
  APPROVE_CAMPAIGN_SUCCESS: 'APPROVE_CAMPAIGN_SUCCESS',

  DELETE_CAMPAIGN_START: 'DELETE_CAMPAIGN_START',
  DELETE_CAMPAIGN_FAILURE: 'DELETE_CAMPAIGN_FAILURE',
  DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',

  SYNC_REPORT_START: 'SYNC_REPORT_START',
  SYNC_REPORT_FAILURE: 'SYNC_REPORT_FAILURE',
  SYNC_REPORT_SUCCESS: 'SYNC_REPORT_SUCCESS',

  FOR_CONTROL_START: 'FOR_CONTROL_START',
  FOR_CONTROL_FAILURE: 'FOR_CONTROL_FAILURE',
  FOR_CONTROL_SUCCESS: 'FOR_CONTROL_SUCCESS',
}
