import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
import axiosClient from 'src/redux/api/axiosClient';
import { REFRESH_TOKEN_BEFORE } from '../config';
import { ACCESS_TOKEN, REFRESH_TOKEN } from './constant';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUserInfo = (userInfo) => {
  if (userInfo) {
    localStorage.setItem('userInfo', userInfo);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('userInfo');
    // delete axios.defaults.headers.common.Authorization;
  }
};

async function getAccessToken() {
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
  if (!refreshToken) {
    return false;
  }

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  if (!accessToken) {
    return false;
  }

  return accessToken;
}

const shouldRefresh = (token) => jwtDecode(token).exp * 1000 - new Date().valueOf() <= REFRESH_TOKEN_BEFORE;

export { isValidToken, getAccessToken, shouldRefresh, setSession, setUserInfo, verify, sign };
