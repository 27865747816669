import React, { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography, Avatar, TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import 'firebase/compat/auth';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { getDeviceName, getOperatingSystem } from '../../utils/commonUtils';
import authService from '../../services/authService';
import LoadingComponent from '../../components/LoadingComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import { encrypt } from '../../utils/crypto';
import { PLATFORM_KEY } from '../../utils/constant';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must have at least 6 characters, maximum 32 characters')
    .max(32, 'Password must have at least 6 characters, maximum 32 characters')
    .required('Password is required')
});

export default function Login() {
  const { method, login } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deviceId, setDeviceId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDeviceId();
  }, []);

  async function getDeviceId() {
    // We recommend to call `load` at application startup.
    const fp = await FingerprintJS.load();

    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get();

    // This is the visitor identifier:
    setDeviceId(result.visitorId);
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const body = {
          app_version: '1.1.1',
          device_info: {
            id: deviceId,
            name: getDeviceName(),
            os: getOperatingSystem()
          },
          platform_key: PLATFORM_KEY,
          email: values.email,
          encrypted_password: encrypt(values.password)
        };
        const response = await authService.loginAccount(body);
        const isSuccess = !!response?.data?.access_token;
        if (isSuccess) {
          login(response.data);
          enqueueSnackbar('Logged in successfully', {
            variant: 'success'
          });
          navigate(PATH_DASHBOARD.root);
        }
        setLoading(false);
        resetForm();
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error?.message, {
          variant: 'error'
        });
        setLoading(false);
      }
    }
  });

  const { errors, touched, getFieldProps, handleSubmit } = formik;

  return (
    <RootStyle title="Login">
      {loading && <LoadingComponent />}
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <ContentStyle>
              <Box>
                <Typography variant="h6" mb={2}>
                  Login Admin Portal
                </Typography>
                <Box display="flex" flexDirection="column" gap={2}>
                  <TextField
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email"
                  />
                  <TextField
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Password"
                    type="password"
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" type="submit">
                    Login
                  </Button>
                </Box>
              </Box>
            </ContentStyle>
          </Form>
        </FormikProvider>
      </Container>
    </RootStyle>
  );
}
