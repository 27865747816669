// import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// //
// import { rootPersistConfig, rootReducer } from './rootReducer';
// import thunk from "redux-thunk"
// // ----------------------------------------------------------------------

// // const store = configureStore({
// //   reducer: persistReducer(rootPersistConfig, rootReducer),
// //   middleware: getDefaultMiddleware({
// //     serializableCheck: false,
// //     immutableCheck: false
// //   })
// // });
// const middlewares = [thunk, createDebounce()]

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const store = createStore(
//   rootReducer,
//   {},
//   composeEnhancers(applyMiddleware(...middlewares))
// )

// const persistor = persistStore(store);

// const useSelector = useReduxSelector;

// const useDispatch = () => useReduxDispatch();

// export { store, persistor, useSelector, useDispatch };
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { rootPersistConfig, rootReducer } from './rootReducer';

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();
export { store, persistor, useSelector, useDispatch };
